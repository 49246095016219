import * as React from 'react'

import PhoneIcon from '@mui/icons-material/Phone'
import { BottomNavigation } from '@mui/material'
import ContactButton from './ContactModal/ContactButton'
import styles from './BottomBar.styles'

export default function BottomBar() {
  return (
    <BottomNavigation className={styles.container} showLabels>
      <ContactButton name="Betti"></ContactButton>

      <PhoneIcon className={styles.bottomPhone} />

      <ContactButton name="Barni"></ContactButton>
    </BottomNavigation>
  )
}
