import * as React from 'react'

import { Button, Paper, Typography } from '@mui/material'
import styles from './ContactModal.styles'
import { primaryColors } from 'styles/colors'

type PopoverModalProps = {
  name: 'Betti' | 'Barni'
  tel: string
  email: string
}
export default function PopoverModal({ name, tel, email }: PopoverModalProps) {
  return (
    <Paper className={styles.paper}>
      <Typography className={styles.name}>{name}</Typography>

      <Button
        onClick={() => {
          window.location.href = `tel:${tel}`
        }}
      >
        <Typography color={primaryColors.PEACH_DARK}>{tel}</Typography>
      </Button>

      <Typography className={styles.contact}>{email}</Typography>
    </Paper>
  )
}
