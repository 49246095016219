import * as React from 'react'
import { Switch } from '@mui/material'
import styles from './QuestionnairePage.styles'

export function Mainquestion({
  question,
  checked,
  onChange,
}: {
  question: { id: string; text: string; details?: string }
  checked: boolean
  onChange: (value) => void
}) {
  return (
    <>
      <h2>{question.text}</h2>
      {question.details && <h5>{question.details}</h5>}

      <Switch
        className={styles.switch}
        // value={checked}
        checked={checked}
        onChange={e => {
          e.preventDefault()
          onChange(e.target.checked)
        }}
      />
    </>
  )
}
