import { keyframes, stylesheet } from 'typestyle'
import { primaryColors } from '../../../styles/colors'

const growAnimation = keyframes({
  '20%': { transform: 'scale(1.2)' },
  '50%': { transform: 'rotateX(-45deg)' },
  '60%': { transform: 'scale(1)' },
  '70%': { transform: 'rotateX(45deg)' },
  '80%': { transform: 'scale(1.2)' },
  '100%': {},
})

const styles = stylesheet({
  container: {
    marginBottom: '20px',
    borderRadius: '12px',
    maxWidth: '550px',
  },
  title: {
    backgroundColor: primaryColors.PEACH_LIGHT2,
  },
  answer: {
    textAlign: 'justify',
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  locationIcon: {
    color: primaryColors.PEACH_DARK,
  },
  directionsIcon: {
    color: primaryColors.PEACH_DARK,
    fontSize: '48px',
  },
  locationButtonContainer: {
    display: 'flex',
    height: '100%',
    animationName: growAnimation,
    animationDuration: '3s',
    animationIterationCount: 'infinite',
  },
  avatar: {
    marginRight: 0,
    width: '40px',
  },
})

export default styles
