import * as React from 'react'
import Loading from 'components/general/Loading'

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import EditIcon from '@mui/icons-material/Edit'
import {
  useGetAnswersByQuestionIdQuery,
  useUpdateAnswerMutation,
  useCreateParticipantAnswerMutation,
} from 'generated/graphql'
import styles from './QuestionnairePage.styles'
import { getInvitationId } from 'utils/getCookies'
import { TextCell } from './TextCell'
import { Participant } from './ParticipantTable'
import { questionText } from 'utils/questionText'
import { useSnackbar } from 'notistack'

export type Answer = {
  __typename?: 'Answer' | undefined
  id: string
  enabled: boolean
  participantId: string
  participantName: string
  question: { id: string }
  isEditMode?: boolean
  answerOption: string
  dummy: boolean
}

export function MultiAnswerQuestionTable({
  question,
  participants,
}: {
  question: {
    id: string
    text: string
    enabled: boolean
    details: string
  }
  participants: Participant[]
}) {
  const [updateAnswer] = useUpdateAnswerMutation()
  const [createAnswer] = useCreateParticipantAnswerMutation()

  const { enqueueSnackbar } = useSnackbar()

  const defaultValues: Answer[] = participants.map(p => {
    return {
      __typename: 'Answer',
      id: p.id,
      enabled: question.enabled,
      question: { id: question.id },
      participantId: p.id,
      participantName: `${p.firstName}`,
      isEditMode: false,
      answerOption: '',
      dummy: true,
    }
  })

  const [rows, setRows] = React.useState<Answer[]>(
    defaultValues.map(d => {
      return {
        ...d,
        id: d.id,
        answerOption: d.answerOption,
      }
    })
  )

  const { error, loading } = useGetAnswersByQuestionIdQuery({
    variables: { invitationId: getInvitationId(), questionId: question.id },
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      if (data.answers.length) {
        setRows(
          participants.map(p => {
            const answer = data.answers.find(a => a.participant?.id === p.id)
            return {
              __typename: 'Answer',
              id: answer?.id || p.id,
              enabled: true,
              question: { id: question.id },
              participantId: p.id,
              participantName: `${p.firstName}`,
              isEditMode: false,
              answerOption: answer?.answerOption?.name || '',
              dummy: !answer,
            }
          })
        )
      }
    },
  })

  const onToggleEditMode = id => {
    setRows(() => {
      return rows.map(row => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode }
        }
        return row
      })
    })
  }

  const onSave = async id => {
    const newRows: Answer[] = await Promise.all(
      rows.map(async row => {
        if (row.id === id) {
          const { id, participantId, question, answerOption, dummy } = row

          if (dummy) {
            await createAnswer({
              variables: {
                answer: answerOption,
                participantId: participantId,
                invitationId: getInvitationId()!,
                questionId: question.id,
              },
              onCompleted: data => {
                row.dummy = false
                row.id = data.createOneAnswer.id
                enqueueSnackbar('Válaszod elmentve', {
                  autoHideDuration: 2000,
                  variant: 'success',
                })
              },
            })
          } else {
            updateAnswer({
              variables: { answerId: id, answer: answerOption },
            })
            enqueueSnackbar('Válaszod elmentve', {
              autoHideDuration: 2000,
              variant: 'success',
            })
          }

          return { ...row, isEditMode: !row.isEditMode }
        }
        return row
      })
    )
    setRows([...newRows])
  }

  const onChange = (e, row) => {
    const value = e.target.value
    const name = e.target.name
    const { id } = row
    const newRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value }
      }
      return row
    })
    setRows(newRows)
  }

  if (!getInvitationId() || !participants || error || loading) {
    return <Loading />
  }

  return (
    <>
      <Paper className={styles.questionTable}>
        <Table aria-label="caption table">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" />
              <TableCell align="left">{questionText(question)}</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell key={row.id}>
                  {row.isEditMode ? (
                    <>
                      <IconButton
                        aria-label="done"
                        onClick={() => onSave(row.id)}
                      >
                        <DoneIcon style={{ fill: 'green' }} />
                      </IconButton>
                    </>
                  ) : (
                    row.enabled && (
                      <IconButton
                        aria-label="edit"
                        onClick={() => onToggleEditMode(row.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    )
                  )}
                </TableCell>
                {(!row.isEditMode || !row.enabled) && (
                  <TextCell
                    {...{
                      row,
                      name: 'participantName',
                      onChange,
                    }}
                  />
                )}
                <TextCell {...{ row, name: 'answerOption', onChange }} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}
