import * as React from 'react'
import { Input, TableCell } from '@mui/material'

export const TextCell = ({
  row,
  name,
  onChange,
}: {
  row: any
  name: string
  onChange: (e, val) => void
}) => {
  const { isEditMode } = row
  return (
    <TableCell align="left">
      {isEditMode ? (
        <Input
          value={row[name]}
          name={name}
          onChange={e => {
            e.preventDefault()
            onChange(e, row)
          }}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  )
}
