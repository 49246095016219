import { keyframes, stylesheet } from 'typestyle'

const growAnimationBetti = keyframes({
  '0%': { transform: 'scale(0.7) translate(-70%, -70%);' },
  '50%': { transform: 'rotate(1.1turn)' },
  '100%': { transform: 'scale(0.7) translate(-70%, -70%)' },
})
const growAnimationBarni = keyframes({
  '0%': { transform: 'rotate(1.1turn)' },
  '50%': { transform: 'scale(0.7) translate(-70%, -70%);' },
  '100%': { transform: 'rotate(1.1turn)' },
})

const styles = stylesheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatarBetti: {
    width: '6rem',
    height: '6rem',
    animationName: growAnimationBetti,
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    margin: '20px',
  },
  avatarBarni: {
    width: '6rem',
    height: '6rem',
    animationName: growAnimationBarni,
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    margin: '20px',
  },
})

export default styles
