import * as React from 'react'
import { Divider } from '@mui/material'
import {
  useGetQuestionnairesQuery,
  useGetAnswersQuery,
  useGetParticipantsQuery,
} from 'generated/graphql'

import styles from './QuestionnairePage.styles'

import { getEventId, getInvitationId } from 'utils/getCookies'
import { QuestionnaireTable } from './QuestionnaireTable'
import { Participant, ParticipantTable } from './ParticipantTable'

import Loading from 'components/general/Loading'

export function QuestionnairePage() {
  const {
    data: questionnaires,
    error,
    loading,
  } = useGetQuestionnairesQuery({
    variables: { eventId: getEventId() },
    fetchPolicy: 'cache-first',
  })
  const { data: answers, refetch: refetchAnswers } = useGetAnswersQuery({
    variables: { invitationId: getInvitationId() },
    fetchPolicy: 'cache-first',
  })
  const [participants, setParticipants] = React.useState<Participant[] | []>([])

  const { refetch: refetchParticipants } = useGetParticipantsQuery({
    variables: { invitationId: getInvitationId() },
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      if (data.participants?.length) {
        setParticipants([...data.participants])
      } else {
        setParticipants([...[]])
      }
    },
  })
  const participantChanged = participants?.length
    ? [...participants]
    : undefined
  React.useEffect(() => {
    refetchParticipants({ invitationId: getInvitationId() })
  }, [participants])

  if (!questionnaires || error || loading || !answers || !participants) {
    return <Loading />
  }

  return (
    <div key="questionnaire_page" className={styles.container}>
      <h1>Ott leszünk </h1>

      <div className={styles.info}>
        Kérjük teljes nevet adjatok meg (ültetőkártyára kerül illetve
        szobafoglaláshoz szükséges)
      </div>
      <Divider></Divider>

      <ParticipantTable
        onParticipantUpdated={() => {
          refetchParticipants({
            invitationId: getInvitationId(),
          })
        }}
      />

      <Divider></Divider>
      {participantChanged && (
        <>
          <h1>Kérdéseink felétek</h1>
          <QuestionnaireTable
            key={
              participantChanged?.sort((b, a) => {
                return (
                  new Date(a.updatedAt).getTime() -
                  new Date(b.updatedAt).getTime()
                )
              })[0]?.updatedAt || 'questionnaire_table_no_participants'
            }
            questionnaires={questionnaires.questionnaires}
            participants={participants}
            onChangeMainAnswer={() => {
              refetchAnswers({ invitationId: getInvitationId() })
            }}
          />
        </>
      )}
    </div>
  )
}
