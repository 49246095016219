import * as React from 'react'
import Popover from '@mui/material/Popover'

import { BottomNavigationAction } from '@mui/material'

import PopoverModal from './PopoverModal'

type ContactButtonProps = {
  name: 'Betti' | 'Barni'
}
export default function ContactButton({ name }: ContactButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const email =
    name === 'Betti' ? 'vatter.betti@gmail.com' : 'barnabas.hajnal@gmail.com'
  const tel = name === 'Betti' ? '+36-30-8373-729' : '+36-30-9769-202'

  return (
    <div>
      <BottomNavigationAction
        showLabel
        onClick={handleClick}
        label={name}
        icon={name === 'Betti' ? '👰🏼‍♀️' : '🤵🏻'}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <PopoverModal name={name} email={email} tel={tel}></PopoverModal>
      </Popover>
    </div>
  )
}
