import * as React from 'react'

import {
  AppBar,
  Box,
  Container,
  IconButton,
  MenuItem,
  Icon,
  Toolbar,
  Typography,
  Menu,
  Button,
} from '@mui/material'

import { Menu as MenuIcon } from '@mui/icons-material'
import styles from './AppBar.styles'
import { ROUTES } from 'components/Routes'

import { useHistory } from 'react-router'
import { primaryColors } from 'styles/colors'

const pages = [
  {
    name: 'Főoldal',
    route: ROUTES.HOMEPAGE,
  },
  {
    name: 'Visszajelzés',
    route: ROUTES.QUESTIONNAIRE,
  },
  {
    name: 'Menetrend',
    route: ROUTES.TIMELINE,
  },
  {
    name: 'Hasznos információk',
    route: ROUTES.FAQ,
  },
  {
    name: 'Galéria',
    route: ROUTES.GALLERY,
  },
]

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null)

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const history = useHistory()
  const handleMenuItemclick = item => {
    history.push(item.route)
    handleCloseNavMenu()
  }

  return (
    <AppBar position="static" className={styles.toolbar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            key="box1"
            sx={{
              flexGrow: { xs: 3, md: 1 },
              display: { xs: 'flex', md: 'none' },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(page => (
                <MenuItem
                  key={`menuitem_${page.name}`}
                  onClick={() => {
                    handleMenuItemclick(page)
                  }}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            key="box2"
            sx={{
              flexGrow: { xs: 4, md: 1 },
              display: { xs: 'flex', md: 'flex' },
            }}
          >
            <Button
              onClick={() => {
                history.push(ROUTES.HOMEPAGE)
              }}
            >
              <Icon key={'logo'} className={styles.imageIcon}>
                <img src="bb-heart-names.svg"></img>
              </Icon>
            </Button>
          </Box>
          <Box
            key="box3"
            sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
          >
            {pages.map(page => (
              <Button
                key={`menuitem_${page.name}`}
                sx={{
                  my: 2,
                  color: primaryColors.PEACH_DARK,
                  textShadow: `-0px 0px 0 ${primaryColors.PEACH_LIGHT1}`,
                  display: 'block',
                }}
                onClick={() => {
                  handleMenuItemclick(page)
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default ResponsiveAppBar
