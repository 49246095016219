import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum AgeCategory {
  Adult = 'Adult',
  Baby = 'Baby',
  Child = 'Child'
}

export type Answer = {
  __typename?: 'Answer';
  answerOption?: Maybe<AnswerOption>;
  answerOptionId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  invitation: Invitation;
  invitationId: Scalars['String'];
  participant?: Maybe<Participant>;
  participantId?: Maybe<Scalars['String']>;
  question: Question;
  questionId: Scalars['String'];
  showQuestions: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type AnswerCreateInput = {
  answerOption?: InputMaybe<AnswerOptionCreateNestedOneWithoutAnswersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invitation: InvitationCreateNestedOneWithoutAnswersInput;
  participant?: InputMaybe<ParticipantCreateNestedOneWithoutAnswersInput>;
  question: QuestionCreateNestedOneWithoutAnswersInput;
  showQuestions?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerCreateManyAnswerOptionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invitationId: Scalars['String'];
  participantId?: InputMaybe<Scalars['String']>;
  questionId: Scalars['String'];
  showQuestions?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerCreateManyAnswerOptionInputEnvelope = {
  data?: InputMaybe<Array<AnswerCreateManyAnswerOptionInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AnswerCreateManyInvitationInput = {
  answerOptionId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  questionId: Scalars['String'];
  showQuestions?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerCreateManyInvitationInputEnvelope = {
  data?: InputMaybe<Array<AnswerCreateManyInvitationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AnswerCreateManyParticipantInput = {
  answerOptionId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invitationId: Scalars['String'];
  questionId: Scalars['String'];
  showQuestions?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerCreateManyParticipantInputEnvelope = {
  data?: InputMaybe<Array<AnswerCreateManyParticipantInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AnswerCreateManyQuestionInput = {
  answerOptionId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invitationId: Scalars['String'];
  participantId?: InputMaybe<Scalars['String']>;
  showQuestions?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerCreateManyQuestionInputEnvelope = {
  data?: InputMaybe<Array<AnswerCreateManyQuestionInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AnswerCreateNestedManyWithoutAnswerOptionInput = {
  connect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerCreateOrConnectWithoutAnswerOptionInput>>;
  create?: InputMaybe<Array<AnswerCreateWithoutAnswerOptionInput>>;
  createMany?: InputMaybe<AnswerCreateManyAnswerOptionInputEnvelope>;
};

export type AnswerCreateNestedManyWithoutInvitationInput = {
  connect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerCreateOrConnectWithoutInvitationInput>>;
  create?: InputMaybe<Array<AnswerCreateWithoutInvitationInput>>;
  createMany?: InputMaybe<AnswerCreateManyInvitationInputEnvelope>;
};

export type AnswerCreateNestedManyWithoutParticipantInput = {
  connect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerCreateOrConnectWithoutParticipantInput>>;
  create?: InputMaybe<Array<AnswerCreateWithoutParticipantInput>>;
  createMany?: InputMaybe<AnswerCreateManyParticipantInputEnvelope>;
};

export type AnswerCreateNestedManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<AnswerCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<AnswerCreateManyQuestionInputEnvelope>;
};

export type AnswerCreateOrConnectWithoutAnswerOptionInput = {
  create: AnswerCreateWithoutAnswerOptionInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerCreateOrConnectWithoutInvitationInput = {
  create: AnswerCreateWithoutInvitationInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerCreateOrConnectWithoutParticipantInput = {
  create: AnswerCreateWithoutParticipantInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerCreateOrConnectWithoutQuestionInput = {
  create: AnswerCreateWithoutQuestionInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerCreateWithoutAnswerOptionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invitation: InvitationCreateNestedOneWithoutAnswersInput;
  participant?: InputMaybe<ParticipantCreateNestedOneWithoutAnswersInput>;
  question: QuestionCreateNestedOneWithoutAnswersInput;
  showQuestions?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerCreateWithoutInvitationInput = {
  answerOption?: InputMaybe<AnswerOptionCreateNestedOneWithoutAnswersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  participant?: InputMaybe<ParticipantCreateNestedOneWithoutAnswersInput>;
  question: QuestionCreateNestedOneWithoutAnswersInput;
  showQuestions?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerCreateWithoutParticipantInput = {
  answerOption?: InputMaybe<AnswerOptionCreateNestedOneWithoutAnswersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invitation: InvitationCreateNestedOneWithoutAnswersInput;
  question: QuestionCreateNestedOneWithoutAnswersInput;
  showQuestions?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerCreateWithoutQuestionInput = {
  answerOption?: InputMaybe<AnswerOptionCreateNestedOneWithoutAnswersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invitation: InvitationCreateNestedOneWithoutAnswersInput;
  participant?: InputMaybe<ParticipantCreateNestedOneWithoutAnswersInput>;
  showQuestions?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerListRelationFilter = {
  every?: InputMaybe<AnswerWhereInput>;
  none?: InputMaybe<AnswerWhereInput>;
  some?: InputMaybe<AnswerWhereInput>;
};

export type AnswerOption = {
  __typename?: 'AnswerOption';
  answers: Array<Answer>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  question?: Maybe<Question>;
  questionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type AnswerOptionAnswersArgs = {
  cursor?: InputMaybe<AnswerWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type AnswerOptionCreateInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutAnswerOptionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  question?: InputMaybe<QuestionCreateNestedOneWithoutOptionsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerOptionCreateManyQuestionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerOptionCreateManyQuestionInputEnvelope = {
  data?: InputMaybe<Array<AnswerOptionCreateManyQuestionInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AnswerOptionCreateNestedManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<AnswerOptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerOptionCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<AnswerOptionCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<AnswerOptionCreateManyQuestionInputEnvelope>;
};

export type AnswerOptionCreateNestedOneWithoutAnswersInput = {
  connect?: InputMaybe<AnswerOptionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnswerOptionCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<AnswerOptionCreateWithoutAnswersInput>;
};

export type AnswerOptionCreateOrConnectWithoutAnswersInput = {
  create: AnswerOptionCreateWithoutAnswersInput;
  where: AnswerOptionWhereUniqueInput;
};

export type AnswerOptionCreateOrConnectWithoutQuestionInput = {
  create: AnswerOptionCreateWithoutQuestionInput;
  where: AnswerOptionWhereUniqueInput;
};

export type AnswerOptionCreateWithoutAnswersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  question?: InputMaybe<QuestionCreateNestedOneWithoutOptionsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerOptionCreateWithoutQuestionInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutAnswerOptionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerOptionListRelationFilter = {
  every?: InputMaybe<AnswerOptionWhereInput>;
  none?: InputMaybe<AnswerOptionWhereInput>;
  some?: InputMaybe<AnswerOptionWhereInput>;
};

export type AnswerOptionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AnswerOptionOrderByWithRelationInput = {
  answers?: InputMaybe<AnswerOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  question?: InputMaybe<QuestionOrderByWithRelationInput>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AnswerOptionScalarWhereInput = {
  AND?: InputMaybe<Array<AnswerOptionScalarWhereInput>>;
  NOT?: InputMaybe<Array<AnswerOptionScalarWhereInput>>;
  OR?: InputMaybe<Array<AnswerOptionScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  questionId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AnswerOptionUpdateInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutAnswerOptionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutOptionsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnswerOptionUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnswerOptionUpdateManyWithWhereWithoutQuestionInput = {
  data: AnswerOptionUpdateManyMutationInput;
  where: AnswerOptionScalarWhereInput;
};

export type AnswerOptionUpdateManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<AnswerOptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerOptionCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<AnswerOptionCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<AnswerOptionCreateManyQuestionInputEnvelope>;
  delete?: InputMaybe<Array<AnswerOptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AnswerOptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AnswerOptionWhereUniqueInput>>;
  set?: InputMaybe<Array<AnswerOptionWhereUniqueInput>>;
  update?: InputMaybe<Array<AnswerOptionUpdateWithWhereUniqueWithoutQuestionInput>>;
  updateMany?: InputMaybe<Array<AnswerOptionUpdateManyWithWhereWithoutQuestionInput>>;
  upsert?: InputMaybe<Array<AnswerOptionUpsertWithWhereUniqueWithoutQuestionInput>>;
};

export type AnswerOptionUpdateOneWithoutAnswersInput = {
  connect?: InputMaybe<AnswerOptionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnswerOptionCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<AnswerOptionCreateWithoutAnswersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AnswerOptionUpdateWithoutAnswersInput>;
  upsert?: InputMaybe<AnswerOptionUpsertWithoutAnswersInput>;
};

export type AnswerOptionUpdateWithWhereUniqueWithoutQuestionInput = {
  data: AnswerOptionUpdateWithoutQuestionInput;
  where: AnswerOptionWhereUniqueInput;
};

export type AnswerOptionUpdateWithoutAnswersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  question?: InputMaybe<QuestionUpdateOneWithoutOptionsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnswerOptionUpdateWithoutQuestionInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutAnswerOptionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnswerOptionUpsertWithWhereUniqueWithoutQuestionInput = {
  create: AnswerOptionCreateWithoutQuestionInput;
  update: AnswerOptionUpdateWithoutQuestionInput;
  where: AnswerOptionWhereUniqueInput;
};

export type AnswerOptionUpsertWithoutAnswersInput = {
  create: AnswerOptionCreateWithoutAnswersInput;
  update: AnswerOptionUpdateWithoutAnswersInput;
};

export type AnswerOptionWhereInput = {
  AND?: InputMaybe<Array<AnswerOptionWhereInput>>;
  NOT?: InputMaybe<Array<AnswerOptionWhereInput>>;
  OR?: InputMaybe<Array<AnswerOptionWhereInput>>;
  answers?: InputMaybe<AnswerListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  question?: InputMaybe<QuestionWhereInput>;
  questionId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AnswerOptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type AnswerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AnswerOrderByWithRelationInput = {
  answerOption?: InputMaybe<AnswerOptionOrderByWithRelationInput>;
  answerOptionId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invitation?: InputMaybe<InvitationOrderByWithRelationInput>;
  invitationId?: InputMaybe<SortOrder>;
  participant?: InputMaybe<ParticipantOrderByWithRelationInput>;
  participantId?: InputMaybe<SortOrder>;
  question?: InputMaybe<QuestionOrderByWithRelationInput>;
  questionId?: InputMaybe<SortOrder>;
  showQuestions?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AnswerScalarWhereInput = {
  AND?: InputMaybe<Array<AnswerScalarWhereInput>>;
  NOT?: InputMaybe<Array<AnswerScalarWhereInput>>;
  OR?: InputMaybe<Array<AnswerScalarWhereInput>>;
  answerOptionId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  invitationId?: InputMaybe<StringFilter>;
  participantId?: InputMaybe<StringNullableFilter>;
  questionId?: InputMaybe<StringFilter>;
  showQuestions?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum AnswerType {
  MultiSelect = 'MultiSelect',
  Select = 'Select',
  Text = 'Text',
  YesNo = 'YesNo'
}

export type AnswerUpdateInput = {
  answerOption?: InputMaybe<AnswerOptionUpdateOneWithoutAnswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitation?: InputMaybe<InvitationUpdateOneRequiredWithoutAnswersInput>;
  participant?: InputMaybe<ParticipantUpdateOneWithoutAnswersInput>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutAnswersInput>;
  showQuestions?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnswerUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  showQuestions?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnswerUpdateManyWithWhereWithoutAnswerOptionInput = {
  data: AnswerUpdateManyMutationInput;
  where: AnswerScalarWhereInput;
};

export type AnswerUpdateManyWithWhereWithoutInvitationInput = {
  data: AnswerUpdateManyMutationInput;
  where: AnswerScalarWhereInput;
};

export type AnswerUpdateManyWithWhereWithoutParticipantInput = {
  data: AnswerUpdateManyMutationInput;
  where: AnswerScalarWhereInput;
};

export type AnswerUpdateManyWithWhereWithoutQuestionInput = {
  data: AnswerUpdateManyMutationInput;
  where: AnswerScalarWhereInput;
};

export type AnswerUpdateManyWithoutAnswerOptionInput = {
  connect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerCreateOrConnectWithoutAnswerOptionInput>>;
  create?: InputMaybe<Array<AnswerCreateWithoutAnswerOptionInput>>;
  createMany?: InputMaybe<AnswerCreateManyAnswerOptionInputEnvelope>;
  delete?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AnswerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  set?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  update?: InputMaybe<Array<AnswerUpdateWithWhereUniqueWithoutAnswerOptionInput>>;
  updateMany?: InputMaybe<Array<AnswerUpdateManyWithWhereWithoutAnswerOptionInput>>;
  upsert?: InputMaybe<Array<AnswerUpsertWithWhereUniqueWithoutAnswerOptionInput>>;
};

export type AnswerUpdateManyWithoutInvitationInput = {
  connect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerCreateOrConnectWithoutInvitationInput>>;
  create?: InputMaybe<Array<AnswerCreateWithoutInvitationInput>>;
  createMany?: InputMaybe<AnswerCreateManyInvitationInputEnvelope>;
  delete?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AnswerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  set?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  update?: InputMaybe<Array<AnswerUpdateWithWhereUniqueWithoutInvitationInput>>;
  updateMany?: InputMaybe<Array<AnswerUpdateManyWithWhereWithoutInvitationInput>>;
  upsert?: InputMaybe<Array<AnswerUpsertWithWhereUniqueWithoutInvitationInput>>;
};

export type AnswerUpdateManyWithoutParticipantInput = {
  connect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerCreateOrConnectWithoutParticipantInput>>;
  create?: InputMaybe<Array<AnswerCreateWithoutParticipantInput>>;
  createMany?: InputMaybe<AnswerCreateManyParticipantInputEnvelope>;
  delete?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AnswerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  set?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  update?: InputMaybe<Array<AnswerUpdateWithWhereUniqueWithoutParticipantInput>>;
  updateMany?: InputMaybe<Array<AnswerUpdateManyWithWhereWithoutParticipantInput>>;
  upsert?: InputMaybe<Array<AnswerUpsertWithWhereUniqueWithoutParticipantInput>>;
};

export type AnswerUpdateManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnswerCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<AnswerCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<AnswerCreateManyQuestionInputEnvelope>;
  delete?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AnswerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  set?: InputMaybe<Array<AnswerWhereUniqueInput>>;
  update?: InputMaybe<Array<AnswerUpdateWithWhereUniqueWithoutQuestionInput>>;
  updateMany?: InputMaybe<Array<AnswerUpdateManyWithWhereWithoutQuestionInput>>;
  upsert?: InputMaybe<Array<AnswerUpsertWithWhereUniqueWithoutQuestionInput>>;
};

export type AnswerUpdateWithWhereUniqueWithoutAnswerOptionInput = {
  data: AnswerUpdateWithoutAnswerOptionInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerUpdateWithWhereUniqueWithoutInvitationInput = {
  data: AnswerUpdateWithoutInvitationInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerUpdateWithWhereUniqueWithoutParticipantInput = {
  data: AnswerUpdateWithoutParticipantInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerUpdateWithWhereUniqueWithoutQuestionInput = {
  data: AnswerUpdateWithoutQuestionInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerUpdateWithoutAnswerOptionInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitation?: InputMaybe<InvitationUpdateOneRequiredWithoutAnswersInput>;
  participant?: InputMaybe<ParticipantUpdateOneWithoutAnswersInput>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutAnswersInput>;
  showQuestions?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnswerUpdateWithoutInvitationInput = {
  answerOption?: InputMaybe<AnswerOptionUpdateOneWithoutAnswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  participant?: InputMaybe<ParticipantUpdateOneWithoutAnswersInput>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutAnswersInput>;
  showQuestions?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnswerUpdateWithoutParticipantInput = {
  answerOption?: InputMaybe<AnswerOptionUpdateOneWithoutAnswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitation?: InputMaybe<InvitationUpdateOneRequiredWithoutAnswersInput>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutAnswersInput>;
  showQuestions?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnswerUpdateWithoutQuestionInput = {
  answerOption?: InputMaybe<AnswerOptionUpdateOneWithoutAnswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitation?: InputMaybe<InvitationUpdateOneRequiredWithoutAnswersInput>;
  participant?: InputMaybe<ParticipantUpdateOneWithoutAnswersInput>;
  showQuestions?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnswerUpsertWithWhereUniqueWithoutAnswerOptionInput = {
  create: AnswerCreateWithoutAnswerOptionInput;
  update: AnswerUpdateWithoutAnswerOptionInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerUpsertWithWhereUniqueWithoutInvitationInput = {
  create: AnswerCreateWithoutInvitationInput;
  update: AnswerUpdateWithoutInvitationInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerUpsertWithWhereUniqueWithoutParticipantInput = {
  create: AnswerCreateWithoutParticipantInput;
  update: AnswerUpdateWithoutParticipantInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerUpsertWithWhereUniqueWithoutQuestionInput = {
  create: AnswerCreateWithoutQuestionInput;
  update: AnswerUpdateWithoutQuestionInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerWhereInput = {
  AND?: InputMaybe<Array<AnswerWhereInput>>;
  NOT?: InputMaybe<Array<AnswerWhereInput>>;
  OR?: InputMaybe<Array<AnswerWhereInput>>;
  answerOption?: InputMaybe<AnswerOptionWhereInput>;
  answerOptionId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  invitation?: InputMaybe<InvitationWhereInput>;
  invitationId?: InputMaybe<StringFilter>;
  participant?: InputMaybe<ParticipantWhereInput>;
  participantId?: InputMaybe<StringNullableFilter>;
  question?: InputMaybe<QuestionWhereInput>;
  questionId?: InputMaybe<StringFilter>;
  showQuestions?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AnswerWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type EnumAgeCategoryFieldUpdateOperationsInput = {
  set?: InputMaybe<AgeCategory>;
};

export type EnumAgeCategoryFilter = {
  equals?: InputMaybe<AgeCategory>;
  in?: InputMaybe<Array<AgeCategory>>;
  not?: InputMaybe<NestedEnumAgeCategoryFilter>;
  notIn?: InputMaybe<Array<AgeCategory>>;
};

export type EnumAnswerTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<AnswerType>;
};

export type EnumAnswerTypeFilter = {
  equals?: InputMaybe<AnswerType>;
  in?: InputMaybe<Array<AnswerType>>;
  not?: InputMaybe<NestedEnumAnswerTypeFilter>;
  notIn?: InputMaybe<Array<AnswerType>>;
};

export type Event = {
  __typename?: 'Event';
  FaqItem: Array<FaqItem>;
  capacity?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  eventStep: Array<EventStep>;
  id: Scalars['String'];
  invitationCard?: Maybe<Scalars['String']>;
  invitationCardBack?: Maybe<Scalars['String']>;
  invitations: Array<Invitation>;
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  questionnaire: Array<Questionnaire>;
  startDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type EventFaqItemArgs = {
  cursor?: InputMaybe<FaqItemWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type EventEventStepArgs = {
  cursor?: InputMaybe<EventStepWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type EventInvitationsArgs = {
  cursor?: InputMaybe<InvitationWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type EventQuestionnaireArgs = {
  cursor?: InputMaybe<QuestionnaireWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type EventCreateInput = {
  FaqItem?: InputMaybe<FaqItemCreateNestedManyWithoutEventInput>;
  capacity?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eventStep?: InputMaybe<EventStepCreateNestedManyWithoutEventInput>;
  id?: InputMaybe<Scalars['String']>;
  invitationCard?: InputMaybe<Scalars['Upload']>;
  invitationCardBack?: InputMaybe<Scalars['Upload']>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEventInput>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  questionnaire?: InputMaybe<QuestionnaireCreateNestedManyWithoutEventInput>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventCreateNestedOneWithoutEventStepInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutEventStepInput>;
  create?: InputMaybe<EventCreateWithoutEventStepInput>;
};

export type EventCreateNestedOneWithoutFaqItemInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutFaqItemInput>;
  create?: InputMaybe<EventCreateWithoutFaqItemInput>;
};

export type EventCreateNestedOneWithoutInvitationsInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutInvitationsInput>;
  create?: InputMaybe<EventCreateWithoutInvitationsInput>;
};

export type EventCreateNestedOneWithoutQuestionnaireInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutQuestionnaireInput>;
  create?: InputMaybe<EventCreateWithoutQuestionnaireInput>;
};

export type EventCreateOrConnectWithoutEventStepInput = {
  create?: InputMaybe<EventCreateWithoutEventStepInput>;
  where?: InputMaybe<EventWhereUniqueInput>;
};

export type EventCreateOrConnectWithoutFaqItemInput = {
  create: EventCreateWithoutFaqItemInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutInvitationsInput = {
  create: EventCreateWithoutInvitationsInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutQuestionnaireInput = {
  create: EventCreateWithoutQuestionnaireInput;
  where: EventWhereUniqueInput;
};

export type EventCreateWithoutEventStepInput = {
  FaqItem?: InputMaybe<FaqItemCreateNestedManyWithoutEventInput>;
  capacity?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invitationCard?: InputMaybe<Scalars['String']>;
  invitationCardBack?: InputMaybe<Scalars['String']>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEventInput>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  questionnaire?: InputMaybe<QuestionnaireCreateNestedManyWithoutEventInput>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventCreateWithoutFaqItemInput = {
  capacity?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eventStep?: InputMaybe<EventStepCreateNestedManyWithoutEventInput>;
  id?: InputMaybe<Scalars['String']>;
  invitationCard?: InputMaybe<Scalars['String']>;
  invitationCardBack?: InputMaybe<Scalars['String']>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEventInput>;
  location?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  questionnaire?: InputMaybe<QuestionnaireCreateNestedManyWithoutEventInput>;
  startDate: Scalars['DateTime'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventCreateWithoutInvitationsInput = {
  FaqItem?: InputMaybe<FaqItemCreateNestedManyWithoutEventInput>;
  capacity?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eventStep?: InputMaybe<EventStepCreateNestedManyWithoutEventInput>;
  id?: InputMaybe<Scalars['String']>;
  invitationCard?: InputMaybe<Scalars['String']>;
  invitationCardBack?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  questionnaire?: InputMaybe<QuestionnaireCreateNestedManyWithoutEventInput>;
  startDate: Scalars['DateTime'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventCreateWithoutQuestionnaireInput = {
  FaqItem?: InputMaybe<FaqItemCreateNestedManyWithoutEventInput>;
  capacity?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eventStep?: InputMaybe<EventStepCreateNestedManyWithoutEventInput>;
  id?: InputMaybe<Scalars['String']>;
  invitationCard?: InputMaybe<Scalars['String']>;
  invitationCardBack?: InputMaybe<Scalars['String']>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEventInput>;
  location?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventOrderByWithRelationInput = {
  FaqItem?: InputMaybe<FaqItemOrderByRelationAggregateInput>;
  capacity?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  eventStep?: InputMaybe<EventStepOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  invitationCard?: InputMaybe<SortOrder>;
  invitationCardBack?: InputMaybe<SortOrder>;
  invitations?: InputMaybe<InvitationOrderByRelationAggregateInput>;
  location?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  questionnaire?: InputMaybe<QuestionnaireOrderByRelationAggregateInput>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EventStep = {
  __typename?: 'EventStep';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  event: Event;
  eventId: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startDateTime: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventStepCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<EventCreateNestedOneWithoutEventStepInput>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['Upload']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventStepCreateManyEventInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  startDateTime: Scalars['DateTime'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventStepCreateManyEventInputEnvelope = {
  data?: InputMaybe<Array<EventStepCreateManyEventInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type EventStepCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<EventStepWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventStepCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<EventStepCreateWithoutEventInput>>;
  createMany?: InputMaybe<EventStepCreateManyEventInputEnvelope>;
};

export type EventStepCreateOrConnectWithoutEventInput = {
  create: EventStepCreateWithoutEventInput;
  where: EventStepWhereUniqueInput;
};

export type EventStepCreateWithoutEventInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['Upload']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventStepListRelationFilter = {
  every?: InputMaybe<EventStepWhereInput>;
  none?: InputMaybe<EventStepWhereInput>;
  some?: InputMaybe<EventStepWhereInput>;
};

export type EventStepOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EventStepOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  endDateTime?: InputMaybe<SortOrder>;
  event?: InputMaybe<EventOrderByWithRelationInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  lat?: InputMaybe<SortOrder>;
  lng?: InputMaybe<SortOrder>;
  location?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  startDateTime?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EventStepScalarWhereInput = {
  AND?: InputMaybe<Array<EventStepScalarWhereInput>>;
  NOT?: InputMaybe<Array<EventStepScalarWhereInput>>;
  OR?: InputMaybe<Array<EventStepScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  endDateTime?: InputMaybe<DateTimeNullableFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringNullableFilter>;
  lat?: InputMaybe<FloatNullableFilter>;
  lng?: InputMaybe<FloatNullableFilter>;
  location?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  startDateTime?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EventStepUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDateTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutEventStepInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<Scalars['Upload']>;
  lat?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDateTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventStepUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDateTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lat?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDateTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventStepUpdateManyWithWhereWithoutEventInput = {
  data: EventStepUpdateManyMutationInput;
  where: EventStepScalarWhereInput;
};

export type EventStepUpdateManyWithoutEventInput = {
  connect?: InputMaybe<Array<EventStepWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventStepCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<EventStepCreateWithoutEventInput>>;
  createMany?: InputMaybe<EventStepCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<EventStepWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventStepScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventStepWhereUniqueInput>>;
  set?: InputMaybe<Array<EventStepWhereUniqueInput>>;
  update?: InputMaybe<Array<EventStepUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<EventStepUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<EventStepUpsertWithWhereUniqueWithoutEventInput>>;
};

export type EventStepUpdateWithWhereUniqueWithoutEventInput = {
  data: EventStepUpdateWithoutEventInput;
  where: EventStepWhereUniqueInput;
};

export type EventStepUpdateWithoutEventInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDateTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<Scalars['Upload']>;
  lat?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDateTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventStepUpsertWithWhereUniqueWithoutEventInput = {
  create: EventStepCreateWithoutEventInput;
  update: EventStepUpdateWithoutEventInput;
  where: EventStepWhereUniqueInput;
};

export type EventStepWhereInput = {
  AND?: InputMaybe<Array<EventStepWhereInput>>;
  NOT?: InputMaybe<Array<EventStepWhereInput>>;
  OR?: InputMaybe<Array<EventStepWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  endDateTime?: InputMaybe<DateTimeNullableFilter>;
  event?: InputMaybe<EventWhereInput>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringNullableFilter>;
  lat?: InputMaybe<FloatNullableFilter>;
  lng?: InputMaybe<FloatNullableFilter>;
  location?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  startDateTime?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EventStepWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type EventUpdateInput = {
  FaqItem?: InputMaybe<FaqItemUpdateManyWithoutEventInput>;
  capacity?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventStep?: InputMaybe<EventStepUpdateManyWithoutEventInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitationCard?: InputMaybe<Scalars['Upload']>;
  invitationCardBack?: InputMaybe<Scalars['Upload']>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEventInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionnaire?: InputMaybe<QuestionnaireUpdateManyWithoutEventInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventUpdateOneRequiredWithoutEventStepInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutEventStepInput>;
  create?: InputMaybe<EventCreateWithoutEventStepInput>;
  update?: InputMaybe<EventUpdateWithoutEventStepInput>;
  upsert?: InputMaybe<EventUpsertWithoutEventStepInput>;
};

export type EventUpdateOneRequiredWithoutFaqItemInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutFaqItemInput>;
  create?: InputMaybe<EventCreateWithoutFaqItemInput>;
  update?: InputMaybe<EventUpdateWithoutFaqItemInput>;
  upsert?: InputMaybe<EventUpsertWithoutFaqItemInput>;
};

export type EventUpdateOneRequiredWithoutInvitationsInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutInvitationsInput>;
  create?: InputMaybe<EventCreateWithoutInvitationsInput>;
  update?: InputMaybe<EventUpdateWithoutInvitationsInput>;
  upsert?: InputMaybe<EventUpsertWithoutInvitationsInput>;
};

export type EventUpdateOneRequiredWithoutQuestionnaireInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutQuestionnaireInput>;
  create?: InputMaybe<EventCreateWithoutQuestionnaireInput>;
  update?: InputMaybe<EventUpdateWithoutQuestionnaireInput>;
  upsert?: InputMaybe<EventUpsertWithoutQuestionnaireInput>;
};

export type EventUpdateWithoutEventStepInput = {
  FaqItem?: InputMaybe<FaqItemUpdateManyWithoutEventInput>;
  capacity?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitationCard?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invitationCardBack?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEventInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionnaire?: InputMaybe<QuestionnaireUpdateManyWithoutEventInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutFaqItemInput = {
  capacity?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventStep?: InputMaybe<EventStepUpdateManyWithoutEventInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitationCard?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invitationCardBack?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEventInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionnaire?: InputMaybe<QuestionnaireUpdateManyWithoutEventInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutInvitationsInput = {
  FaqItem?: InputMaybe<FaqItemUpdateManyWithoutEventInput>;
  capacity?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventStep?: InputMaybe<EventStepUpdateManyWithoutEventInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitationCard?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invitationCardBack?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionnaire?: InputMaybe<QuestionnaireUpdateManyWithoutEventInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutQuestionnaireInput = {
  FaqItem?: InputMaybe<FaqItemUpdateManyWithoutEventInput>;
  capacity?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventStep?: InputMaybe<EventStepUpdateManyWithoutEventInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitationCard?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invitationCardBack?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEventInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventUpsertWithoutEventStepInput = {
  create?: InputMaybe<EventCreateWithoutEventStepInput>;
  update?: InputMaybe<EventUpdateWithoutEventStepInput>;
};

export type EventUpsertWithoutFaqItemInput = {
  create: EventCreateWithoutFaqItemInput;
  update: EventUpdateWithoutFaqItemInput;
};

export type EventUpsertWithoutInvitationsInput = {
  create: EventCreateWithoutInvitationsInput;
  update: EventUpdateWithoutInvitationsInput;
};

export type EventUpsertWithoutQuestionnaireInput = {
  create: EventCreateWithoutQuestionnaireInput;
  update: EventUpdateWithoutQuestionnaireInput;
};

export type EventWhereInput = {
  AND?: InputMaybe<Array<EventWhereInput>>;
  FaqItem?: InputMaybe<FaqItemListRelationFilter>;
  NOT?: InputMaybe<Array<EventWhereInput>>;
  OR?: InputMaybe<Array<EventWhereInput>>;
  capacity?: InputMaybe<DecimalNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  eventStep?: InputMaybe<EventStepListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  invitationCard?: InputMaybe<StringNullableFilter>;
  invitationCardBack?: InputMaybe<StringNullableFilter>;
  invitations?: InputMaybe<InvitationListRelationFilter>;
  location?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  questionnaire?: InputMaybe<QuestionnaireListRelationFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EventWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type FaqItem = {
  __typename?: 'FaqItem';
  answer: Scalars['String'];
  createdAt: Scalars['DateTime'];
  event: Event;
  eventId: Scalars['String'];
  id: Scalars['String'];
  order: Scalars['Int'];
  question: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type FaqItemCreateInput = {
  answer: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: EventCreateNestedOneWithoutFaqItemInput;
  id?: InputMaybe<Scalars['String']>;
  order: Scalars['Int'];
  question: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type FaqItemCreateManyEventInput = {
  answer: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order: Scalars['Int'];
  question: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type FaqItemCreateManyEventInputEnvelope = {
  data?: InputMaybe<Array<FaqItemCreateManyEventInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FaqItemCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FaqItemCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<FaqItemCreateWithoutEventInput>>;
  createMany?: InputMaybe<FaqItemCreateManyEventInputEnvelope>;
};

export type FaqItemCreateOrConnectWithoutEventInput = {
  create: FaqItemCreateWithoutEventInput;
  where: FaqItemWhereUniqueInput;
};

export type FaqItemCreateWithoutEventInput = {
  answer: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order: Scalars['Int'];
  question: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type FaqItemListRelationFilter = {
  every?: InputMaybe<FaqItemWhereInput>;
  none?: InputMaybe<FaqItemWhereInput>;
  some?: InputMaybe<FaqItemWhereInput>;
};

export type FaqItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FaqItemOrderByWithRelationInput = {
  answer?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  event?: InputMaybe<EventOrderByWithRelationInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type FaqItemScalarWhereInput = {
  AND?: InputMaybe<Array<FaqItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<FaqItemScalarWhereInput>>;
  OR?: InputMaybe<Array<FaqItemScalarWhereInput>>;
  answer?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  question?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type FaqItemUpdateInput = {
  answer?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutFaqItemInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  question?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FaqItemUpdateManyMutationInput = {
  answer?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  question?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FaqItemUpdateManyWithWhereWithoutEventInput = {
  data: FaqItemUpdateManyMutationInput;
  where: FaqItemScalarWhereInput;
};

export type FaqItemUpdateManyWithoutEventInput = {
  connect?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FaqItemCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<FaqItemCreateWithoutEventInput>>;
  createMany?: InputMaybe<FaqItemCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FaqItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  set?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  update?: InputMaybe<Array<FaqItemUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<FaqItemUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<FaqItemUpsertWithWhereUniqueWithoutEventInput>>;
};

export type FaqItemUpdateWithWhereUniqueWithoutEventInput = {
  data: FaqItemUpdateWithoutEventInput;
  where: FaqItemWhereUniqueInput;
};

export type FaqItemUpdateWithoutEventInput = {
  answer?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  question?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FaqItemUpsertWithWhereUniqueWithoutEventInput = {
  create: FaqItemCreateWithoutEventInput;
  update: FaqItemUpdateWithoutEventInput;
  where: FaqItemWhereUniqueInput;
};

export type FaqItemWhereInput = {
  AND?: InputMaybe<Array<FaqItemWhereInput>>;
  NOT?: InputMaybe<Array<FaqItemWhereInput>>;
  OR?: InputMaybe<Array<FaqItemWhereInput>>;
  answer?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  event?: InputMaybe<EventWhereInput>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  question?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type FaqItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Invitation = {
  __typename?: 'Invitation';
  answers: Array<Answer>;
  card?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  event: Event;
  eventId: Scalars['String'];
  id: Scalars['String'];
  invitee: Scalars['String'];
  participants: Array<Participant>;
  updatedAt: Scalars['DateTime'];
};


export type InvitationAnswersArgs = {
  cursor?: InputMaybe<AnswerWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type InvitationParticipantsArgs = {
  cursor?: InputMaybe<ParticipantWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type InvitationAuthPayload = {
  __typename?: 'InvitationAuthPayload';
  eventId?: Maybe<Scalars['String']>;
  invitationId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type InvitationCreateInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutInvitationInput>;
  card?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: EventCreateNestedOneWithoutInvitationsInput;
  id?: InputMaybe<Scalars['String']>;
  invitee: Scalars['String'];
  participants?: InputMaybe<ParticipantCreateNestedManyWithoutInvitationInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InvitationCreateManyEventInput = {
  card?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invitee: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InvitationCreateManyEventInputEnvelope = {
  data?: InputMaybe<Array<InvitationCreateManyEventInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InvitationCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<InvitationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvitationCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<InvitationCreateWithoutEventInput>>;
  createMany?: InputMaybe<InvitationCreateManyEventInputEnvelope>;
};

export type InvitationCreateNestedOneWithoutAnswersInput = {
  connect?: InputMaybe<InvitationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InvitationCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<InvitationCreateWithoutAnswersInput>;
};

export type InvitationCreateNestedOneWithoutParticipantsInput = {
  connect?: InputMaybe<InvitationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InvitationCreateOrConnectWithoutParticipantsInput>;
  create?: InputMaybe<InvitationCreateWithoutParticipantsInput>;
};

export type InvitationCreateOrConnectWithoutAnswersInput = {
  create: InvitationCreateWithoutAnswersInput;
  where: InvitationWhereUniqueInput;
};

export type InvitationCreateOrConnectWithoutEventInput = {
  create: InvitationCreateWithoutEventInput;
  where: InvitationWhereUniqueInput;
};

export type InvitationCreateOrConnectWithoutParticipantsInput = {
  create: InvitationCreateWithoutParticipantsInput;
  where: InvitationWhereUniqueInput;
};

export type InvitationCreateWithoutAnswersInput = {
  card?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: EventCreateNestedOneWithoutInvitationsInput;
  id?: InputMaybe<Scalars['String']>;
  invitee: Scalars['String'];
  participants?: InputMaybe<ParticipantCreateNestedManyWithoutInvitationInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InvitationCreateWithoutEventInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutInvitationInput>;
  card?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  invitee: Scalars['String'];
  participants?: InputMaybe<ParticipantCreateNestedManyWithoutInvitationInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InvitationCreateWithoutParticipantsInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutInvitationInput>;
  card?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: EventCreateNestedOneWithoutInvitationsInput;
  id?: InputMaybe<Scalars['String']>;
  invitee: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InvitationListRelationFilter = {
  every?: InputMaybe<InvitationWhereInput>;
  none?: InputMaybe<InvitationWhereInput>;
  some?: InputMaybe<InvitationWhereInput>;
};

export type InvitationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InvitationOrderByWithRelationInput = {
  answers?: InputMaybe<AnswerOrderByRelationAggregateInput>;
  card?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  event?: InputMaybe<EventOrderByWithRelationInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invitee?: InputMaybe<SortOrder>;
  participants?: InputMaybe<ParticipantOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InvitationScalarWhereInput = {
  AND?: InputMaybe<Array<InvitationScalarWhereInput>>;
  NOT?: InputMaybe<Array<InvitationScalarWhereInput>>;
  OR?: InputMaybe<Array<InvitationScalarWhereInput>>;
  card?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  invitee?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InvitationUpdateInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutInvitationInput>;
  card?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutInvitationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitee?: InputMaybe<StringFieldUpdateOperationsInput>;
  participants?: InputMaybe<ParticipantUpdateManyWithoutInvitationInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InvitationUpdateManyMutationInput = {
  card?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitee?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InvitationUpdateManyWithWhereWithoutEventInput = {
  data: InvitationUpdateManyMutationInput;
  where: InvitationScalarWhereInput;
};

export type InvitationUpdateManyWithoutEventInput = {
  connect?: InputMaybe<Array<InvitationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvitationCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<InvitationCreateWithoutEventInput>>;
  createMany?: InputMaybe<InvitationCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<InvitationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InvitationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InvitationWhereUniqueInput>>;
  set?: InputMaybe<Array<InvitationWhereUniqueInput>>;
  update?: InputMaybe<Array<InvitationUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<InvitationUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<InvitationUpsertWithWhereUniqueWithoutEventInput>>;
};

export type InvitationUpdateOneRequiredWithoutAnswersInput = {
  connect?: InputMaybe<InvitationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InvitationCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<InvitationCreateWithoutAnswersInput>;
  update?: InputMaybe<InvitationUpdateWithoutAnswersInput>;
  upsert?: InputMaybe<InvitationUpsertWithoutAnswersInput>;
};

export type InvitationUpdateOneRequiredWithoutParticipantsInput = {
  connect?: InputMaybe<InvitationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InvitationCreateOrConnectWithoutParticipantsInput>;
  create?: InputMaybe<InvitationCreateWithoutParticipantsInput>;
  update?: InputMaybe<InvitationUpdateWithoutParticipantsInput>;
  upsert?: InputMaybe<InvitationUpsertWithoutParticipantsInput>;
};

export type InvitationUpdateWithWhereUniqueWithoutEventInput = {
  data: InvitationUpdateWithoutEventInput;
  where: InvitationWhereUniqueInput;
};

export type InvitationUpdateWithoutAnswersInput = {
  card?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutInvitationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitee?: InputMaybe<StringFieldUpdateOperationsInput>;
  participants?: InputMaybe<ParticipantUpdateManyWithoutInvitationInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InvitationUpdateWithoutEventInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutInvitationInput>;
  card?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitee?: InputMaybe<StringFieldUpdateOperationsInput>;
  participants?: InputMaybe<ParticipantUpdateManyWithoutInvitationInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InvitationUpdateWithoutParticipantsInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutInvitationInput>;
  card?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutInvitationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitee?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InvitationUpsertWithWhereUniqueWithoutEventInput = {
  create: InvitationCreateWithoutEventInput;
  update: InvitationUpdateWithoutEventInput;
  where: InvitationWhereUniqueInput;
};

export type InvitationUpsertWithoutAnswersInput = {
  create: InvitationCreateWithoutAnswersInput;
  update: InvitationUpdateWithoutAnswersInput;
};

export type InvitationUpsertWithoutParticipantsInput = {
  create: InvitationCreateWithoutParticipantsInput;
  update: InvitationUpdateWithoutParticipantsInput;
};

export type InvitationWhereInput = {
  AND?: InputMaybe<Array<InvitationWhereInput>>;
  NOT?: InputMaybe<Array<InvitationWhereInput>>;
  OR?: InputMaybe<Array<InvitationWhereInput>>;
  answers?: InputMaybe<AnswerListRelationFilter>;
  card?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  event?: InputMaybe<EventWhereInput>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  invitee?: InputMaybe<StringFilter>;
  participants?: InputMaybe<ParticipantListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InvitationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  Login?: Maybe<AuthPayload>;
  LoginWithInvitation?: Maybe<InvitationAuthPayload>;
  createOneAnswer: Answer;
  createOneAnswerOption: AnswerOption;
  createOneEvent: Event;
  createOneEventStep: EventStep;
  createOneFaqItem: FaqItem;
  createOneInvitation: Invitation;
  createOneParticipant: Participant;
  createOneQuestion: Question;
  createOneQuestionnaire: Questionnaire;
  createOneUser: User;
  deleteOneAnswer?: Maybe<Answer>;
  deleteOneAnswerOption?: Maybe<AnswerOption>;
  deleteOneEvent?: Maybe<Event>;
  deleteOneEventStep?: Maybe<EventStep>;
  deleteOneFaqItem?: Maybe<FaqItem>;
  deleteOneInvitation?: Maybe<Invitation>;
  deleteOneParticipant?: Maybe<Participant>;
  deleteOneQuestion?: Maybe<Question>;
  deleteOneQuestionnaire?: Maybe<Questionnaire>;
  deleteOneUser?: Maybe<User>;
  updateOneAnswer?: Maybe<Answer>;
  updateOneAnswerOption?: Maybe<AnswerOption>;
  updateOneEvent?: Maybe<Event>;
  updateOneEventStep?: Maybe<EventStep>;
  updateOneFaqItem?: Maybe<FaqItem>;
  updateOneInvitation?: Maybe<Invitation>;
  updateOneParticipant?: Maybe<Participant>;
  updateOneQuestion?: Maybe<Question>;
  updateOneQuestionnaire?: Maybe<Questionnaire>;
  updateOneUser?: Maybe<User>;
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  userName: Scalars['String'];
};


export type MutationLoginWithInvitationArgs = {
  eventId: Scalars['String'];
  invitationId: Scalars['String'];
};


export type MutationCreateOneAnswerArgs = {
  data: AnswerCreateInput;
};


export type MutationCreateOneAnswerOptionArgs = {
  data: AnswerOptionCreateInput;
};


export type MutationCreateOneEventArgs = {
  data: EventCreateInput;
};


export type MutationCreateOneEventStepArgs = {
  data: EventStepCreateInput;
};


export type MutationCreateOneFaqItemArgs = {
  data: FaqItemCreateInput;
};


export type MutationCreateOneInvitationArgs = {
  data: InvitationCreateInput;
};


export type MutationCreateOneParticipantArgs = {
  data: ParticipantCreateInput;
};


export type MutationCreateOneQuestionArgs = {
  data: QuestionCreateInput;
};


export type MutationCreateOneQuestionnaireArgs = {
  data: QuestionnaireCreateInput;
};


export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteOneAnswerArgs = {
  where: AnswerWhereUniqueInput;
};


export type MutationDeleteOneAnswerOptionArgs = {
  where: AnswerOptionWhereUniqueInput;
};


export type MutationDeleteOneEventArgs = {
  where: EventWhereUniqueInput;
};


export type MutationDeleteOneEventStepArgs = {
  where: EventStepWhereUniqueInput;
};


export type MutationDeleteOneFaqItemArgs = {
  where: FaqItemWhereUniqueInput;
};


export type MutationDeleteOneInvitationArgs = {
  where: InvitationWhereUniqueInput;
};


export type MutationDeleteOneParticipantArgs = {
  where: ParticipantWhereUniqueInput;
};


export type MutationDeleteOneQuestionArgs = {
  where: QuestionWhereUniqueInput;
};


export type MutationDeleteOneQuestionnaireArgs = {
  where: QuestionnaireWhereUniqueInput;
};


export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationUpdateOneAnswerArgs = {
  data: AnswerUpdateInput;
  where: AnswerWhereUniqueInput;
};


export type MutationUpdateOneAnswerOptionArgs = {
  data: AnswerOptionUpdateInput;
  where: AnswerOptionWhereUniqueInput;
};


export type MutationUpdateOneEventArgs = {
  data: EventUpdateInput;
  where: EventWhereUniqueInput;
};


export type MutationUpdateOneEventStepArgs = {
  data: EventStepUpdateInput;
  where: EventStepWhereUniqueInput;
};


export type MutationUpdateOneFaqItemArgs = {
  data: FaqItemUpdateInput;
  where: FaqItemWhereUniqueInput;
};


export type MutationUpdateOneInvitationArgs = {
  data: InvitationUpdateInput;
  where: InvitationWhereUniqueInput;
};


export type MutationUpdateOneParticipantArgs = {
  data: ParticipantUpdateInput;
  where: ParticipantWhereUniqueInput;
};


export type MutationUpdateOneQuestionArgs = {
  data: QuestionUpdateInput;
  where: QuestionWhereUniqueInput;
};


export type MutationUpdateOneQuestionnaireArgs = {
  data: QuestionnaireUpdateInput;
  where: QuestionnaireWhereUniqueInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedEnumAgeCategoryFilter = {
  equals?: InputMaybe<AgeCategory>;
  in?: InputMaybe<Array<AgeCategory>>;
  not?: InputMaybe<NestedEnumAgeCategoryFilter>;
  notIn?: InputMaybe<Array<AgeCategory>>;
};

export type NestedEnumAnswerTypeFilter = {
  equals?: InputMaybe<AnswerType>;
  in?: InputMaybe<Array<AnswerType>>;
  not?: InputMaybe<NestedEnumAnswerTypeFilter>;
  notIn?: InputMaybe<Array<AnswerType>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableDecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Decimal']>;
  divide?: InputMaybe<Scalars['Decimal']>;
  increment?: InputMaybe<Scalars['Decimal']>;
  multiply?: InputMaybe<Scalars['Decimal']>;
  set?: InputMaybe<Scalars['Decimal']>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type Participant = {
  __typename?: 'Participant';
  ageCategory: AgeCategory;
  answers: Array<Answer>;
  createdAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  invitation: Invitation;
  invitationId: Scalars['String'];
  lastName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type ParticipantAnswersArgs = {
  cursor?: InputMaybe<AnswerWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type ParticipantCreateInput = {
  ageCategory?: InputMaybe<AgeCategory>;
  answers?: InputMaybe<AnswerCreateNestedManyWithoutParticipantInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  invitation: InvitationCreateNestedOneWithoutParticipantsInput;
  lastName: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ParticipantCreateManyInvitationInput = {
  ageCategory?: InputMaybe<AgeCategory>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ParticipantCreateManyInvitationInputEnvelope = {
  data?: InputMaybe<Array<ParticipantCreateManyInvitationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ParticipantCreateNestedManyWithoutInvitationInput = {
  connect?: InputMaybe<Array<ParticipantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ParticipantCreateOrConnectWithoutInvitationInput>>;
  create?: InputMaybe<Array<ParticipantCreateWithoutInvitationInput>>;
  createMany?: InputMaybe<ParticipantCreateManyInvitationInputEnvelope>;
};

export type ParticipantCreateNestedOneWithoutAnswersInput = {
  connect?: InputMaybe<ParticipantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ParticipantCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<ParticipantCreateWithoutAnswersInput>;
};

export type ParticipantCreateOrConnectWithoutAnswersInput = {
  create: ParticipantCreateWithoutAnswersInput;
  where: ParticipantWhereUniqueInput;
};

export type ParticipantCreateOrConnectWithoutInvitationInput = {
  create: ParticipantCreateWithoutInvitationInput;
  where: ParticipantWhereUniqueInput;
};

export type ParticipantCreateWithoutAnswersInput = {
  ageCategory?: InputMaybe<AgeCategory>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  invitation: InvitationCreateNestedOneWithoutParticipantsInput;
  lastName: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ParticipantCreateWithoutInvitationInput = {
  ageCategory?: InputMaybe<AgeCategory>;
  answers?: InputMaybe<AnswerCreateNestedManyWithoutParticipantInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ParticipantListRelationFilter = {
  every?: InputMaybe<ParticipantWhereInput>;
  none?: InputMaybe<ParticipantWhereInput>;
  some?: InputMaybe<ParticipantWhereInput>;
};

export type ParticipantOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ParticipantOrderByWithRelationInput = {
  ageCategory?: InputMaybe<SortOrder>;
  answers?: InputMaybe<AnswerOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invitation?: InputMaybe<InvitationOrderByWithRelationInput>;
  invitationId?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ParticipantScalarWhereInput = {
  AND?: InputMaybe<Array<ParticipantScalarWhereInput>>;
  NOT?: InputMaybe<Array<ParticipantScalarWhereInput>>;
  OR?: InputMaybe<Array<ParticipantScalarWhereInput>>;
  ageCategory?: InputMaybe<EnumAgeCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  invitationId?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ParticipantUpdateInput = {
  ageCategory?: InputMaybe<EnumAgeCategoryFieldUpdateOperationsInput>;
  answers?: InputMaybe<AnswerUpdateManyWithoutParticipantInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitation?: InputMaybe<InvitationUpdateOneRequiredWithoutParticipantsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ParticipantUpdateManyMutationInput = {
  ageCategory?: InputMaybe<EnumAgeCategoryFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ParticipantUpdateManyWithWhereWithoutInvitationInput = {
  data: ParticipantUpdateManyMutationInput;
  where: ParticipantScalarWhereInput;
};

export type ParticipantUpdateManyWithoutInvitationInput = {
  connect?: InputMaybe<Array<ParticipantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ParticipantCreateOrConnectWithoutInvitationInput>>;
  create?: InputMaybe<Array<ParticipantCreateWithoutInvitationInput>>;
  createMany?: InputMaybe<ParticipantCreateManyInvitationInputEnvelope>;
  delete?: InputMaybe<Array<ParticipantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ParticipantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ParticipantWhereUniqueInput>>;
  set?: InputMaybe<Array<ParticipantWhereUniqueInput>>;
  update?: InputMaybe<Array<ParticipantUpdateWithWhereUniqueWithoutInvitationInput>>;
  updateMany?: InputMaybe<Array<ParticipantUpdateManyWithWhereWithoutInvitationInput>>;
  upsert?: InputMaybe<Array<ParticipantUpsertWithWhereUniqueWithoutInvitationInput>>;
};

export type ParticipantUpdateOneWithoutAnswersInput = {
  connect?: InputMaybe<ParticipantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ParticipantCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<ParticipantCreateWithoutAnswersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ParticipantUpdateWithoutAnswersInput>;
  upsert?: InputMaybe<ParticipantUpsertWithoutAnswersInput>;
};

export type ParticipantUpdateWithWhereUniqueWithoutInvitationInput = {
  data: ParticipantUpdateWithoutInvitationInput;
  where: ParticipantWhereUniqueInput;
};

export type ParticipantUpdateWithoutAnswersInput = {
  ageCategory?: InputMaybe<EnumAgeCategoryFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitation?: InputMaybe<InvitationUpdateOneRequiredWithoutParticipantsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ParticipantUpdateWithoutInvitationInput = {
  ageCategory?: InputMaybe<EnumAgeCategoryFieldUpdateOperationsInput>;
  answers?: InputMaybe<AnswerUpdateManyWithoutParticipantInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ParticipantUpsertWithWhereUniqueWithoutInvitationInput = {
  create: ParticipantCreateWithoutInvitationInput;
  update: ParticipantUpdateWithoutInvitationInput;
  where: ParticipantWhereUniqueInput;
};

export type ParticipantUpsertWithoutAnswersInput = {
  create: ParticipantCreateWithoutAnswersInput;
  update: ParticipantUpdateWithoutAnswersInput;
};

export type ParticipantWhereInput = {
  AND?: InputMaybe<Array<ParticipantWhereInput>>;
  NOT?: InputMaybe<Array<ParticipantWhereInput>>;
  OR?: InputMaybe<Array<ParticipantWhereInput>>;
  ageCategory?: InputMaybe<EnumAgeCategoryFilter>;
  answers?: InputMaybe<AnswerListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  invitation?: InputMaybe<InvitationWhereInput>;
  invitationId?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ParticipantWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  answer?: Maybe<Answer>;
  answerOption?: Maybe<AnswerOption>;
  answerOptions: Array<AnswerOption>;
  answerOptionsCount?: Maybe<Scalars['Int']>;
  answers: Array<Answer>;
  answersCount?: Maybe<Scalars['Int']>;
  event?: Maybe<Event>;
  eventStep?: Maybe<EventStep>;
  eventSteps: Array<EventStep>;
  eventStepsCount?: Maybe<Scalars['Int']>;
  events: Array<Event>;
  eventsCount?: Maybe<Scalars['Int']>;
  faqItem?: Maybe<FaqItem>;
  faqItems: Array<FaqItem>;
  faqItemsCount?: Maybe<Scalars['Int']>;
  invitation?: Maybe<Invitation>;
  invitations: Array<Invitation>;
  invitationsCount?: Maybe<Scalars['Int']>;
  participant?: Maybe<Participant>;
  participants: Array<Participant>;
  participantsCount?: Maybe<Scalars['Int']>;
  question?: Maybe<Question>;
  questionnaire?: Maybe<Questionnaire>;
  questionnaires: Array<Questionnaire>;
  questionnairesCount?: Maybe<Scalars['Int']>;
  questions: Array<Question>;
  questionsCount?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  users: Array<User>;
  usersCount?: Maybe<Scalars['Int']>;
};


export type QueryAnswerArgs = {
  where: AnswerWhereUniqueInput;
};


export type QueryAnswerOptionArgs = {
  where: AnswerOptionWhereUniqueInput;
};


export type QueryAnswerOptionsArgs = {
  cursor?: InputMaybe<AnswerOptionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AnswerOptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnswerOptionWhereInput>;
};


export type QueryAnswerOptionsCountArgs = {
  cursor?: InputMaybe<AnswerOptionWhereUniqueInput>;
  orderBy?: InputMaybe<AnswerOptionOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnswerOptionWhereInput>;
};


export type QueryAnswersArgs = {
  cursor?: InputMaybe<AnswerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AnswerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnswerWhereInput>;
};


export type QueryAnswersCountArgs = {
  cursor?: InputMaybe<AnswerWhereUniqueInput>;
  orderBy?: InputMaybe<AnswerOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnswerWhereInput>;
};


export type QueryEventArgs = {
  where: EventWhereUniqueInput;
};


export type QueryEventStepArgs = {
  where: EventStepWhereUniqueInput;
};


export type QueryEventStepsArgs = {
  cursor?: InputMaybe<EventStepWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EventStepOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventStepWhereInput>;
};


export type QueryEventStepsCountArgs = {
  cursor?: InputMaybe<EventStepWhereUniqueInput>;
  orderBy?: InputMaybe<EventStepOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventStepWhereInput>;
};


export type QueryEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryEventsCountArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  orderBy?: InputMaybe<EventOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryFaqItemArgs = {
  where: FaqItemWhereUniqueInput;
};


export type QueryFaqItemsArgs = {
  cursor?: InputMaybe<FaqItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FaqItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqItemWhereInput>;
};


export type QueryFaqItemsCountArgs = {
  cursor?: InputMaybe<FaqItemWhereUniqueInput>;
  orderBy?: InputMaybe<FaqItemOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqItemWhereInput>;
};


export type QueryInvitationArgs = {
  where: InvitationWhereUniqueInput;
};


export type QueryInvitationsArgs = {
  cursor?: InputMaybe<InvitationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InvitationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvitationWhereInput>;
};


export type QueryInvitationsCountArgs = {
  cursor?: InputMaybe<InvitationWhereUniqueInput>;
  orderBy?: InputMaybe<InvitationOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvitationWhereInput>;
};


export type QueryParticipantArgs = {
  where: ParticipantWhereUniqueInput;
};


export type QueryParticipantsArgs = {
  cursor?: InputMaybe<ParticipantWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ParticipantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ParticipantWhereInput>;
};


export type QueryParticipantsCountArgs = {
  cursor?: InputMaybe<ParticipantWhereUniqueInput>;
  orderBy?: InputMaybe<ParticipantOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ParticipantWhereInput>;
};


export type QueryQuestionArgs = {
  where: QuestionWhereUniqueInput;
};


export type QueryQuestionnaireArgs = {
  where: QuestionnaireWhereUniqueInput;
};


export type QueryQuestionnairesArgs = {
  cursor?: InputMaybe<QuestionnaireWhereUniqueInput>;
  orderBy?: InputMaybe<Array<QuestionnaireOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionnaireWhereInput>;
};


export type QueryQuestionnairesCountArgs = {
  cursor?: InputMaybe<QuestionnaireWhereUniqueInput>;
  orderBy?: InputMaybe<QuestionnaireOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionnaireWhereInput>;
};


export type QueryQuestionsArgs = {
  cursor?: InputMaybe<QuestionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<QuestionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionWhereInput>;
};


export type QueryQuestionsCountArgs = {
  cursor?: InputMaybe<QuestionWhereUniqueInput>;
  orderBy?: InputMaybe<QuestionOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryUsersCountArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<UserOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Question = {
  __typename?: 'Question';
  answers: Array<Answer>;
  createdAt: Scalars['DateTime'];
  details?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  mainQuestionnaire?: Maybe<Questionnaire>;
  multiAnswer: Scalars['Boolean'];
  options: Array<AnswerOption>;
  order: Scalars['Int'];
  questionnaire: Questionnaire;
  questionnaireId: Scalars['String'];
  text: Scalars['String'];
  type: AnswerType;
  updatedAt: Scalars['DateTime'];
};


export type QuestionAnswersArgs = {
  cursor?: InputMaybe<AnswerWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QuestionOptionsArgs = {
  cursor?: InputMaybe<AnswerOptionWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QuestionCreateInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  mainQuestionnaire?: InputMaybe<QuestionnaireCreateNestedOneWithoutMainQuestionInput>;
  multiAnswer?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<AnswerOptionCreateNestedManyWithoutQuestionInput>;
  order?: InputMaybe<Scalars['Int']>;
  questionnaire: QuestionnaireCreateNestedOneWithoutQuestionsInput;
  text: Scalars['String'];
  type?: InputMaybe<AnswerType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateManyQuestionnaireInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  multiAnswer?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  text: Scalars['String'];
  type?: InputMaybe<AnswerType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateManyQuestionnaireInputEnvelope = {
  data?: InputMaybe<Array<QuestionCreateManyQuestionnaireInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type QuestionCreateNestedManyWithoutQuestionnaireInput = {
  connect?: InputMaybe<Array<QuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionCreateOrConnectWithoutQuestionnaireInput>>;
  create?: InputMaybe<Array<QuestionCreateWithoutQuestionnaireInput>>;
  createMany?: InputMaybe<QuestionCreateManyQuestionnaireInputEnvelope>;
};

export type QuestionCreateNestedOneWithoutAnswersInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<QuestionCreateWithoutAnswersInput>;
};

export type QuestionCreateNestedOneWithoutMainQuestionnaireInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutMainQuestionnaireInput>;
  create?: InputMaybe<QuestionCreateWithoutMainQuestionnaireInput>;
};

export type QuestionCreateNestedOneWithoutOptionsInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutOptionsInput>;
  create?: InputMaybe<QuestionCreateWithoutOptionsInput>;
};

export type QuestionCreateOrConnectWithoutAnswersInput = {
  create: QuestionCreateWithoutAnswersInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionCreateOrConnectWithoutMainQuestionnaireInput = {
  create: QuestionCreateWithoutMainQuestionnaireInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionCreateOrConnectWithoutOptionsInput = {
  create: QuestionCreateWithoutOptionsInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionCreateOrConnectWithoutQuestionnaireInput = {
  create: QuestionCreateWithoutQuestionnaireInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionCreateWithoutAnswersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  mainQuestionnaire?: InputMaybe<QuestionnaireCreateNestedOneWithoutMainQuestionInput>;
  multiAnswer?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<AnswerOptionCreateNestedManyWithoutQuestionInput>;
  order?: InputMaybe<Scalars['Int']>;
  questionnaire: QuestionnaireCreateNestedOneWithoutQuestionsInput;
  text: Scalars['String'];
  type?: InputMaybe<AnswerType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateWithoutMainQuestionnaireInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  multiAnswer?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<AnswerOptionCreateNestedManyWithoutQuestionInput>;
  order?: InputMaybe<Scalars['Int']>;
  questionnaire: QuestionnaireCreateNestedOneWithoutQuestionsInput;
  text: Scalars['String'];
  type?: InputMaybe<AnswerType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateWithoutOptionsInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  mainQuestionnaire?: InputMaybe<QuestionnaireCreateNestedOneWithoutMainQuestionInput>;
  multiAnswer?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  questionnaire: QuestionnaireCreateNestedOneWithoutQuestionsInput;
  text: Scalars['String'];
  type?: InputMaybe<AnswerType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateWithoutQuestionnaireInput = {
  answers?: InputMaybe<AnswerCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  mainQuestionnaire?: InputMaybe<QuestionnaireCreateNestedOneWithoutMainQuestionInput>;
  multiAnswer?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<AnswerOptionCreateNestedManyWithoutQuestionInput>;
  order?: InputMaybe<Scalars['Int']>;
  text: Scalars['String'];
  type?: InputMaybe<AnswerType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionListRelationFilter = {
  every?: InputMaybe<QuestionWhereInput>;
  none?: InputMaybe<QuestionWhereInput>;
  some?: InputMaybe<QuestionWhereInput>;
};

export type QuestionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type QuestionOrderByWithRelationInput = {
  answers?: InputMaybe<AnswerOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  details?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainQuestionnaire?: InputMaybe<QuestionnaireOrderByWithRelationInput>;
  multiAnswer?: InputMaybe<SortOrder>;
  options?: InputMaybe<AnswerOptionOrderByRelationAggregateInput>;
  order?: InputMaybe<SortOrder>;
  questionnaire?: InputMaybe<QuestionnaireOrderByWithRelationInput>;
  questionnaireId?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QuestionOrderQuestionnaireIdCompoundUniqueInput = {
  order: Scalars['Int'];
  questionnaireId: Scalars['String'];
};

export type QuestionScalarWhereInput = {
  AND?: InputMaybe<Array<QuestionScalarWhereInput>>;
  NOT?: InputMaybe<Array<QuestionScalarWhereInput>>;
  OR?: InputMaybe<Array<QuestionScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  details?: InputMaybe<StringNullableFilter>;
  enabled?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  multiAnswer?: InputMaybe<BoolFilter>;
  order?: InputMaybe<IntFilter>;
  questionnaireId?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumAnswerTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type QuestionUpdateInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutQuestionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainQuestionnaire?: InputMaybe<QuestionnaireUpdateOneWithoutMainQuestionInput>;
  multiAnswer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  options?: InputMaybe<AnswerOptionUpdateManyWithoutQuestionInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionnaire?: InputMaybe<QuestionnaireUpdateOneRequiredWithoutQuestionsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAnswerTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  multiAnswer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAnswerTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpdateManyWithWhereWithoutQuestionnaireInput = {
  data: QuestionUpdateManyMutationInput;
  where: QuestionScalarWhereInput;
};

export type QuestionUpdateManyWithoutQuestionnaireInput = {
  connect?: InputMaybe<Array<QuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionCreateOrConnectWithoutQuestionnaireInput>>;
  create?: InputMaybe<Array<QuestionCreateWithoutQuestionnaireInput>>;
  createMany?: InputMaybe<QuestionCreateManyQuestionnaireInputEnvelope>;
  delete?: InputMaybe<Array<QuestionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<QuestionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<QuestionWhereUniqueInput>>;
  set?: InputMaybe<Array<QuestionWhereUniqueInput>>;
  update?: InputMaybe<Array<QuestionUpdateWithWhereUniqueWithoutQuestionnaireInput>>;
  updateMany?: InputMaybe<Array<QuestionUpdateManyWithWhereWithoutQuestionnaireInput>>;
  upsert?: InputMaybe<Array<QuestionUpsertWithWhereUniqueWithoutQuestionnaireInput>>;
};

export type QuestionUpdateOneRequiredWithoutAnswersInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutAnswersInput>;
  create?: InputMaybe<QuestionCreateWithoutAnswersInput>;
  update?: InputMaybe<QuestionUpdateWithoutAnswersInput>;
  upsert?: InputMaybe<QuestionUpsertWithoutAnswersInput>;
};

export type QuestionUpdateOneWithoutMainQuestionnaireInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutMainQuestionnaireInput>;
  create?: InputMaybe<QuestionCreateWithoutMainQuestionnaireInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<QuestionUpdateWithoutMainQuestionnaireInput>;
  upsert?: InputMaybe<QuestionUpsertWithoutMainQuestionnaireInput>;
};

export type QuestionUpdateOneWithoutOptionsInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutOptionsInput>;
  create?: InputMaybe<QuestionCreateWithoutOptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<QuestionUpdateWithoutOptionsInput>;
  upsert?: InputMaybe<QuestionUpsertWithoutOptionsInput>;
};

export type QuestionUpdateWithWhereUniqueWithoutQuestionnaireInput = {
  data: QuestionUpdateWithoutQuestionnaireInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionUpdateWithoutAnswersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainQuestionnaire?: InputMaybe<QuestionnaireUpdateOneWithoutMainQuestionInput>;
  multiAnswer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  options?: InputMaybe<AnswerOptionUpdateManyWithoutQuestionInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionnaire?: InputMaybe<QuestionnaireUpdateOneRequiredWithoutQuestionsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAnswerTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpdateWithoutMainQuestionnaireInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutQuestionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  multiAnswer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  options?: InputMaybe<AnswerOptionUpdateManyWithoutQuestionInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionnaire?: InputMaybe<QuestionnaireUpdateOneRequiredWithoutQuestionsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAnswerTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpdateWithoutOptionsInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutQuestionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainQuestionnaire?: InputMaybe<QuestionnaireUpdateOneWithoutMainQuestionInput>;
  multiAnswer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionnaire?: InputMaybe<QuestionnaireUpdateOneRequiredWithoutQuestionsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAnswerTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpdateWithoutQuestionnaireInput = {
  answers?: InputMaybe<AnswerUpdateManyWithoutQuestionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainQuestionnaire?: InputMaybe<QuestionnaireUpdateOneWithoutMainQuestionInput>;
  multiAnswer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  options?: InputMaybe<AnswerOptionUpdateManyWithoutQuestionInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAnswerTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type QuestionUpsertWithWhereUniqueWithoutQuestionnaireInput = {
  create: QuestionCreateWithoutQuestionnaireInput;
  update: QuestionUpdateWithoutQuestionnaireInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionUpsertWithoutAnswersInput = {
  create: QuestionCreateWithoutAnswersInput;
  update: QuestionUpdateWithoutAnswersInput;
};

export type QuestionUpsertWithoutMainQuestionnaireInput = {
  create: QuestionCreateWithoutMainQuestionnaireInput;
  update: QuestionUpdateWithoutMainQuestionnaireInput;
};

export type QuestionUpsertWithoutOptionsInput = {
  create: QuestionCreateWithoutOptionsInput;
  update: QuestionUpdateWithoutOptionsInput;
};

export type QuestionWhereInput = {
  AND?: InputMaybe<Array<QuestionWhereInput>>;
  NOT?: InputMaybe<Array<QuestionWhereInput>>;
  OR?: InputMaybe<Array<QuestionWhereInput>>;
  answers?: InputMaybe<AnswerListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  details?: InputMaybe<StringNullableFilter>;
  enabled?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  mainQuestionnaire?: InputMaybe<QuestionnaireWhereInput>;
  multiAnswer?: InputMaybe<BoolFilter>;
  options?: InputMaybe<AnswerOptionListRelationFilter>;
  order?: InputMaybe<IntFilter>;
  questionnaire?: InputMaybe<QuestionnaireWhereInput>;
  questionnaireId?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumAnswerTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type QuestionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  order_questionnaireId?: InputMaybe<QuestionOrderQuestionnaireIdCompoundUniqueInput>;
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  createdAt: Scalars['DateTime'];
  event: Event;
  eventId: Scalars['String'];
  id: Scalars['String'];
  mainQuestion?: Maybe<Question>;
  mainQuestionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  questions: Array<Question>;
  updatedAt: Scalars['DateTime'];
};


export type QuestionnaireQuestionsArgs = {
  cursor?: InputMaybe<QuestionWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QuestionnaireCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: EventCreateNestedOneWithoutQuestionnaireInput;
  id?: InputMaybe<Scalars['String']>;
  mainQuestion?: InputMaybe<QuestionCreateNestedOneWithoutMainQuestionnaireInput>;
  name: Scalars['String'];
  order: Scalars['Int'];
  questions?: InputMaybe<QuestionCreateNestedManyWithoutQuestionnaireInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionnaireCreateManyEventInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mainQuestionId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionnaireCreateManyEventInputEnvelope = {
  data?: InputMaybe<Array<QuestionnaireCreateManyEventInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type QuestionnaireCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<QuestionnaireWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionnaireCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<QuestionnaireCreateWithoutEventInput>>;
  createMany?: InputMaybe<QuestionnaireCreateManyEventInputEnvelope>;
};

export type QuestionnaireCreateNestedOneWithoutMainQuestionInput = {
  connect?: InputMaybe<QuestionnaireWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionnaireCreateOrConnectWithoutMainQuestionInput>;
  create?: InputMaybe<QuestionnaireCreateWithoutMainQuestionInput>;
};

export type QuestionnaireCreateNestedOneWithoutQuestionsInput = {
  connect?: InputMaybe<QuestionnaireWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionnaireCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<QuestionnaireCreateWithoutQuestionsInput>;
};

export type QuestionnaireCreateOrConnectWithoutEventInput = {
  create: QuestionnaireCreateWithoutEventInput;
  where: QuestionnaireWhereUniqueInput;
};

export type QuestionnaireCreateOrConnectWithoutMainQuestionInput = {
  create: QuestionnaireCreateWithoutMainQuestionInput;
  where: QuestionnaireWhereUniqueInput;
};

export type QuestionnaireCreateOrConnectWithoutQuestionsInput = {
  create: QuestionnaireCreateWithoutQuestionsInput;
  where: QuestionnaireWhereUniqueInput;
};

export type QuestionnaireCreateWithoutEventInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mainQuestion?: InputMaybe<QuestionCreateNestedOneWithoutMainQuestionnaireInput>;
  name: Scalars['String'];
  order: Scalars['Int'];
  questions?: InputMaybe<QuestionCreateNestedManyWithoutQuestionnaireInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionnaireCreateWithoutMainQuestionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: EventCreateNestedOneWithoutQuestionnaireInput;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  questions?: InputMaybe<QuestionCreateNestedManyWithoutQuestionnaireInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionnaireCreateWithoutQuestionsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event: EventCreateNestedOneWithoutQuestionnaireInput;
  id?: InputMaybe<Scalars['String']>;
  mainQuestion?: InputMaybe<QuestionCreateNestedOneWithoutMainQuestionnaireInput>;
  name: Scalars['String'];
  order: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionnaireListRelationFilter = {
  every?: InputMaybe<QuestionnaireWhereInput>;
  none?: InputMaybe<QuestionnaireWhereInput>;
  some?: InputMaybe<QuestionnaireWhereInput>;
};

export type QuestionnaireOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type QuestionnaireOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  event?: InputMaybe<EventOrderByWithRelationInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainQuestion?: InputMaybe<QuestionOrderByWithRelationInput>;
  mainQuestionId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  questions?: InputMaybe<QuestionOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QuestionnaireScalarWhereInput = {
  AND?: InputMaybe<Array<QuestionnaireScalarWhereInput>>;
  NOT?: InputMaybe<Array<QuestionnaireScalarWhereInput>>;
  OR?: InputMaybe<Array<QuestionnaireScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  mainQuestionId?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type QuestionnaireUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutQuestionnaireInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainQuestion?: InputMaybe<QuestionUpdateOneWithoutMainQuestionnaireInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<QuestionUpdateManyWithoutQuestionnaireInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type QuestionnaireUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type QuestionnaireUpdateManyWithWhereWithoutEventInput = {
  data: QuestionnaireUpdateManyMutationInput;
  where: QuestionnaireScalarWhereInput;
};

export type QuestionnaireUpdateManyWithoutEventInput = {
  connect?: InputMaybe<Array<QuestionnaireWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionnaireCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<QuestionnaireCreateWithoutEventInput>>;
  createMany?: InputMaybe<QuestionnaireCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<QuestionnaireWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<QuestionnaireScalarWhereInput>>;
  disconnect?: InputMaybe<Array<QuestionnaireWhereUniqueInput>>;
  set?: InputMaybe<Array<QuestionnaireWhereUniqueInput>>;
  update?: InputMaybe<Array<QuestionnaireUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<QuestionnaireUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<QuestionnaireUpsertWithWhereUniqueWithoutEventInput>>;
};

export type QuestionnaireUpdateOneRequiredWithoutQuestionsInput = {
  connect?: InputMaybe<QuestionnaireWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionnaireCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<QuestionnaireCreateWithoutQuestionsInput>;
  update?: InputMaybe<QuestionnaireUpdateWithoutQuestionsInput>;
  upsert?: InputMaybe<QuestionnaireUpsertWithoutQuestionsInput>;
};

export type QuestionnaireUpdateOneWithoutMainQuestionInput = {
  connect?: InputMaybe<QuestionnaireWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionnaireCreateOrConnectWithoutMainQuestionInput>;
  create?: InputMaybe<QuestionnaireCreateWithoutMainQuestionInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<QuestionnaireUpdateWithoutMainQuestionInput>;
  upsert?: InputMaybe<QuestionnaireUpsertWithoutMainQuestionInput>;
};

export type QuestionnaireUpdateWithWhereUniqueWithoutEventInput = {
  data: QuestionnaireUpdateWithoutEventInput;
  where: QuestionnaireWhereUniqueInput;
};

export type QuestionnaireUpdateWithoutEventInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainQuestion?: InputMaybe<QuestionUpdateOneWithoutMainQuestionnaireInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<QuestionUpdateManyWithoutQuestionnaireInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type QuestionnaireUpdateWithoutMainQuestionInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutQuestionnaireInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<QuestionUpdateManyWithoutQuestionnaireInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type QuestionnaireUpdateWithoutQuestionsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneRequiredWithoutQuestionnaireInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mainQuestion?: InputMaybe<QuestionUpdateOneWithoutMainQuestionnaireInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type QuestionnaireUpsertWithWhereUniqueWithoutEventInput = {
  create: QuestionnaireCreateWithoutEventInput;
  update: QuestionnaireUpdateWithoutEventInput;
  where: QuestionnaireWhereUniqueInput;
};

export type QuestionnaireUpsertWithoutMainQuestionInput = {
  create: QuestionnaireCreateWithoutMainQuestionInput;
  update: QuestionnaireUpdateWithoutMainQuestionInput;
};

export type QuestionnaireUpsertWithoutQuestionsInput = {
  create: QuestionnaireCreateWithoutQuestionsInput;
  update: QuestionnaireUpdateWithoutQuestionsInput;
};

export type QuestionnaireWhereInput = {
  AND?: InputMaybe<Array<QuestionnaireWhereInput>>;
  NOT?: InputMaybe<Array<QuestionnaireWhereInput>>;
  OR?: InputMaybe<Array<QuestionnaireWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  event?: InputMaybe<EventWhereInput>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  mainQuestion?: InputMaybe<QuestionWhereInput>;
  mainQuestionId?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  questions?: InputMaybe<QuestionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type QuestionnaireWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  mainQuestionId?: InputMaybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  failCount: Scalars['Int'];
  id: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userName: Scalars['String'];
};

export type UserCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  failCount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  password: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userName: Scalars['String'];
};

export type UserOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  failCount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  isLocked?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userName?: InputMaybe<SortOrder>;
};

export type UserUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  failCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userName?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  failCount?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  isAdmin?: InputMaybe<BoolFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isLocked?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userName?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
};


export const GetAnswersDocument = gql`
    query getAnswers($invitationId: String) {
  answers(
    where: {invitationId: {equals: $invitationId}}
    orderBy: {updatedAt: desc}
  ) {
    id
    showQuestions
    answerOption {
      id
      name
    }
    participant {
      id
      firstName
      lastName
      ageCategory
    }
    question {
      id
      text
      type
      enabled
      options {
        name
      }
      multiAnswer
      order
      mainQuestionnaire {
        id
      }
      questionnaire {
        id
      }
    }
  }
}
    `;

/**
 * __useGetAnswersQuery__
 *
 * To run a query within a React component, call `useGetAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswersQuery({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useGetAnswersQuery(baseOptions?: Apollo.QueryHookOptions<GetAnswersQuery, GetAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnswersQuery, GetAnswersQueryVariables>(GetAnswersDocument, options);
      }
export function useGetAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnswersQuery, GetAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnswersQuery, GetAnswersQueryVariables>(GetAnswersDocument, options);
        }
export type GetAnswersQueryHookResult = ReturnType<typeof useGetAnswersQuery>;
export type GetAnswersLazyQueryHookResult = ReturnType<typeof useGetAnswersLazyQuery>;
export type GetAnswersQueryResult = Apollo.QueryResult<GetAnswersQuery, GetAnswersQueryVariables>;
export const GetAnswersByQuestionIdDocument = gql`
    query getAnswersByQuestionId($invitationId: String, $questionId: String) {
  answers(
    where: {questionId: {equals: $questionId}, invitationId: {equals: $invitationId}}
    orderBy: {updatedAt: desc}
  ) {
    id
    showQuestions
    answerOption {
      id
      name
    }
    participant {
      id
      firstName
      lastName
      ageCategory
    }
    question {
      id
      text
      type
      enabled
      options {
        name
      }
      multiAnswer
      order
      mainQuestionnaire {
        id
      }
      questionnaire {
        id
      }
    }
  }
}
    `;

/**
 * __useGetAnswersByQuestionIdQuery__
 *
 * To run a query within a React component, call `useGetAnswersByQuestionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswersByQuestionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswersByQuestionIdQuery({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useGetAnswersByQuestionIdQuery(baseOptions?: Apollo.QueryHookOptions<GetAnswersByQuestionIdQuery, GetAnswersByQuestionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnswersByQuestionIdQuery, GetAnswersByQuestionIdQueryVariables>(GetAnswersByQuestionIdDocument, options);
      }
export function useGetAnswersByQuestionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnswersByQuestionIdQuery, GetAnswersByQuestionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnswersByQuestionIdQuery, GetAnswersByQuestionIdQueryVariables>(GetAnswersByQuestionIdDocument, options);
        }
export type GetAnswersByQuestionIdQueryHookResult = ReturnType<typeof useGetAnswersByQuestionIdQuery>;
export type GetAnswersByQuestionIdLazyQueryHookResult = ReturnType<typeof useGetAnswersByQuestionIdLazyQuery>;
export type GetAnswersByQuestionIdQueryResult = Apollo.QueryResult<GetAnswersByQuestionIdQuery, GetAnswersByQuestionIdQueryVariables>;
export const UpdateAnswerDocument = gql`
    mutation updateAnswer($answerId: String!, $answer: String) {
  updateOneAnswer(
    where: {id: $answerId}
    data: {answerOption: {update: {name: {set: $answer}}}}
  ) {
    id
  }
}
    `;
export type UpdateAnswerMutationFn = Apollo.MutationFunction<UpdateAnswerMutation, UpdateAnswerMutationVariables>;

/**
 * __useUpdateAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnswerMutation, { data, loading, error }] = useUpdateAnswerMutation({
 *   variables: {
 *      answerId: // value for 'answerId'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useUpdateAnswerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnswerMutation, UpdateAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnswerMutation, UpdateAnswerMutationVariables>(UpdateAnswerDocument, options);
      }
export type UpdateAnswerMutationHookResult = ReturnType<typeof useUpdateAnswerMutation>;
export type UpdateAnswerMutationResult = Apollo.MutationResult<UpdateAnswerMutation>;
export type UpdateAnswerMutationOptions = Apollo.BaseMutationOptions<UpdateAnswerMutation, UpdateAnswerMutationVariables>;
export const CreateParticipantAnswerDocument = gql`
    mutation createParticipantAnswer($participantId: String!, $questionId: String!, $answer: String!, $invitationId: String!) {
  createOneAnswer(
    data: {answerOption: {create: {name: $answer}}, participant: {connect: {id: $participantId}}, question: {connect: {id: $questionId}}, invitation: {connect: {id: $invitationId}}}
  ) {
    id
  }
}
    `;
export type CreateParticipantAnswerMutationFn = Apollo.MutationFunction<CreateParticipantAnswerMutation, CreateParticipantAnswerMutationVariables>;

/**
 * __useCreateParticipantAnswerMutation__
 *
 * To run a mutation, you first call `useCreateParticipantAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParticipantAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParticipantAnswerMutation, { data, loading, error }] = useCreateParticipantAnswerMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      questionId: // value for 'questionId'
 *      answer: // value for 'answer'
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useCreateParticipantAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateParticipantAnswerMutation, CreateParticipantAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateParticipantAnswerMutation, CreateParticipantAnswerMutationVariables>(CreateParticipantAnswerDocument, options);
      }
export type CreateParticipantAnswerMutationHookResult = ReturnType<typeof useCreateParticipantAnswerMutation>;
export type CreateParticipantAnswerMutationResult = Apollo.MutationResult<CreateParticipantAnswerMutation>;
export type CreateParticipantAnswerMutationOptions = Apollo.BaseMutationOptions<CreateParticipantAnswerMutation, CreateParticipantAnswerMutationVariables>;
export const CreateInvitationAnswerDocument = gql`
    mutation createInvitationAnswer($questionId: String!, $answer: String!, $invitationId: String!) {
  createOneAnswer(
    data: {answerOption: {create: {name: $answer}}, question: {connect: {id: $questionId}}, invitation: {connect: {id: $invitationId}}}
  ) {
    id
  }
}
    `;
export type CreateInvitationAnswerMutationFn = Apollo.MutationFunction<CreateInvitationAnswerMutation, CreateInvitationAnswerMutationVariables>;

/**
 * __useCreateInvitationAnswerMutation__
 *
 * To run a mutation, you first call `useCreateInvitationAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitationAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitationAnswerMutation, { data, loading, error }] = useCreateInvitationAnswerMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      answer: // value for 'answer'
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useCreateInvitationAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvitationAnswerMutation, CreateInvitationAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvitationAnswerMutation, CreateInvitationAnswerMutationVariables>(CreateInvitationAnswerDocument, options);
      }
export type CreateInvitationAnswerMutationHookResult = ReturnType<typeof useCreateInvitationAnswerMutation>;
export type CreateInvitationAnswerMutationResult = Apollo.MutationResult<CreateInvitationAnswerMutation>;
export type CreateInvitationAnswerMutationOptions = Apollo.BaseMutationOptions<CreateInvitationAnswerMutation, CreateInvitationAnswerMutationVariables>;
export const SetMainQuestionAnswerDocument = gql`
    mutation setMainQuestionAnswer($answerId: String!, $answer: Boolean!) {
  updateOneAnswer(where: {id: $answerId}, data: {showQuestions: {set: $answer}}) {
    id
  }
}
    `;
export type SetMainQuestionAnswerMutationFn = Apollo.MutationFunction<SetMainQuestionAnswerMutation, SetMainQuestionAnswerMutationVariables>;

/**
 * __useSetMainQuestionAnswerMutation__
 *
 * To run a mutation, you first call `useSetMainQuestionAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMainQuestionAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMainQuestionAnswerMutation, { data, loading, error }] = useSetMainQuestionAnswerMutation({
 *   variables: {
 *      answerId: // value for 'answerId'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useSetMainQuestionAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SetMainQuestionAnswerMutation, SetMainQuestionAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMainQuestionAnswerMutation, SetMainQuestionAnswerMutationVariables>(SetMainQuestionAnswerDocument, options);
      }
export type SetMainQuestionAnswerMutationHookResult = ReturnType<typeof useSetMainQuestionAnswerMutation>;
export type SetMainQuestionAnswerMutationResult = Apollo.MutationResult<SetMainQuestionAnswerMutation>;
export type SetMainQuestionAnswerMutationOptions = Apollo.BaseMutationOptions<SetMainQuestionAnswerMutation, SetMainQuestionAnswerMutationVariables>;
export const CreateMainQuestionAnswerDocument = gql`
    mutation createMainQuestionAnswer($questionId: String!, $answer: Boolean!, $invitationId: String!) {
  updateOneQuestion(
    where: {id: $questionId}
    data: {answers: {create: {invitation: {connect: {id: $invitationId}}, showQuestions: $answer}}}
  ) {
    id
  }
}
    `;
export type CreateMainQuestionAnswerMutationFn = Apollo.MutationFunction<CreateMainQuestionAnswerMutation, CreateMainQuestionAnswerMutationVariables>;

/**
 * __useCreateMainQuestionAnswerMutation__
 *
 * To run a mutation, you first call `useCreateMainQuestionAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMainQuestionAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMainQuestionAnswerMutation, { data, loading, error }] = useCreateMainQuestionAnswerMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      answer: // value for 'answer'
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useCreateMainQuestionAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateMainQuestionAnswerMutation, CreateMainQuestionAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMainQuestionAnswerMutation, CreateMainQuestionAnswerMutationVariables>(CreateMainQuestionAnswerDocument, options);
      }
export type CreateMainQuestionAnswerMutationHookResult = ReturnType<typeof useCreateMainQuestionAnswerMutation>;
export type CreateMainQuestionAnswerMutationResult = Apollo.MutationResult<CreateMainQuestionAnswerMutation>;
export type CreateMainQuestionAnswerMutationOptions = Apollo.BaseMutationOptions<CreateMainQuestionAnswerMutation, CreateMainQuestionAnswerMutationVariables>;
export const GetEventDocument = gql`
    query getEvent($eventId: String) {
  event(where: {id: $eventId}) {
    id
    name
    startDate
    endDate
    location
    capacity
  }
}
    `;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetEventQuery(baseOptions?: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
      }
export function useGetEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
        }
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventQueryResult = Apollo.QueryResult<GetEventQuery, GetEventQueryVariables>;
export const GetFaqDocument = gql`
    query getFaq($eventId: String) {
  faqItems(where: {eventId: {equals: $eventId}}, orderBy: {order: asc}) {
    id
    question
    answer
    url
    order
  }
}
    `;

/**
 * __useGetFaqQuery__
 *
 * To run a query within a React component, call `useGetFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaqQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetFaqQuery(baseOptions?: Apollo.QueryHookOptions<GetFaqQuery, GetFaqQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFaqQuery, GetFaqQueryVariables>(GetFaqDocument, options);
      }
export function useGetFaqLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFaqQuery, GetFaqQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFaqQuery, GetFaqQueryVariables>(GetFaqDocument, options);
        }
export type GetFaqQueryHookResult = ReturnType<typeof useGetFaqQuery>;
export type GetFaqLazyQueryHookResult = ReturnType<typeof useGetFaqLazyQuery>;
export type GetFaqQueryResult = Apollo.QueryResult<GetFaqQuery, GetFaqQueryVariables>;
export const GetInvitationDocument = gql`
    query getInvitation($invitationId: String) {
  invitation(where: {id: $invitationId}) {
    id
    invitee
  }
}
    `;

/**
 * __useGetInvitationQuery__
 *
 * To run a query within a React component, call `useGetInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationQuery({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useGetInvitationQuery(baseOptions?: Apollo.QueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
      }
export function useGetInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
        }
export type GetInvitationQueryHookResult = ReturnType<typeof useGetInvitationQuery>;
export type GetInvitationLazyQueryHookResult = ReturnType<typeof useGetInvitationLazyQuery>;
export type GetInvitationQueryResult = Apollo.QueryResult<GetInvitationQuery, GetInvitationQueryVariables>;
export const LoginWithInvitationDocument = gql`
    mutation loginWithInvitation($invitationId: String!, $eventId: String!) {
  LoginWithInvitation(invitationId: $invitationId, eventId: $eventId) {
    eventId
    invitationId
    token
  }
}
    `;
export type LoginWithInvitationMutationFn = Apollo.MutationFunction<LoginWithInvitationMutation, LoginWithInvitationMutationVariables>;

/**
 * __useLoginWithInvitationMutation__
 *
 * To run a mutation, you first call `useLoginWithInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithInvitationMutation, { data, loading, error }] = useLoginWithInvitationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useLoginWithInvitationMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithInvitationMutation, LoginWithInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithInvitationMutation, LoginWithInvitationMutationVariables>(LoginWithInvitationDocument, options);
      }
export type LoginWithInvitationMutationHookResult = ReturnType<typeof useLoginWithInvitationMutation>;
export type LoginWithInvitationMutationResult = Apollo.MutationResult<LoginWithInvitationMutation>;
export type LoginWithInvitationMutationOptions = Apollo.BaseMutationOptions<LoginWithInvitationMutation, LoginWithInvitationMutationVariables>;
export const GetParticipantsDocument = gql`
    query getParticipants($invitationId: String) {
  participants(
    where: {invitationId: {equals: $invitationId}}
    orderBy: {createdAt: asc}
  ) {
    id
    firstName
    lastName
    ageCategory
    updatedAt
  }
}
    `;

/**
 * __useGetParticipantsQuery__
 *
 * To run a query within a React component, call `useGetParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsQuery({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useGetParticipantsQuery(baseOptions?: Apollo.QueryHookOptions<GetParticipantsQuery, GetParticipantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParticipantsQuery, GetParticipantsQueryVariables>(GetParticipantsDocument, options);
      }
export function useGetParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParticipantsQuery, GetParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParticipantsQuery, GetParticipantsQueryVariables>(GetParticipantsDocument, options);
        }
export type GetParticipantsQueryHookResult = ReturnType<typeof useGetParticipantsQuery>;
export type GetParticipantsLazyQueryHookResult = ReturnType<typeof useGetParticipantsLazyQuery>;
export type GetParticipantsQueryResult = Apollo.QueryResult<GetParticipantsQuery, GetParticipantsQueryVariables>;
export const UpdateParticipantDocument = gql`
    mutation updateParticipant($id: String!, $firstName: String, $lastName: String, $ageCategory: AgeCategory) {
  updateOneParticipant(
    where: {id: $id}
    data: {firstName: {set: $firstName}, lastName: {set: $lastName}, ageCategory: {set: $ageCategory}}
  ) {
    id
    firstName
    lastName
    ageCategory
  }
}
    `;
export type UpdateParticipantMutationFn = Apollo.MutationFunction<UpdateParticipantMutation, UpdateParticipantMutationVariables>;

/**
 * __useUpdateParticipantMutation__
 *
 * To run a mutation, you first call `useUpdateParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipantMutation, { data, loading, error }] = useUpdateParticipantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      ageCategory: // value for 'ageCategory'
 *   },
 * });
 */
export function useUpdateParticipantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateParticipantMutation, UpdateParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateParticipantMutation, UpdateParticipantMutationVariables>(UpdateParticipantDocument, options);
      }
export type UpdateParticipantMutationHookResult = ReturnType<typeof useUpdateParticipantMutation>;
export type UpdateParticipantMutationResult = Apollo.MutationResult<UpdateParticipantMutation>;
export type UpdateParticipantMutationOptions = Apollo.BaseMutationOptions<UpdateParticipantMutation, UpdateParticipantMutationVariables>;
export const InsertParticipantDocument = gql`
    mutation insertParticipant($invitationId: String!) {
  createOneParticipant(
    data: {invitation: {connect: {id: $invitationId}}, firstName: "", lastName: "", ageCategory: Adult}
  ) {
    id
    firstName
    lastName
    ageCategory
    updatedAt
  }
}
    `;
export type InsertParticipantMutationFn = Apollo.MutationFunction<InsertParticipantMutation, InsertParticipantMutationVariables>;

/**
 * __useInsertParticipantMutation__
 *
 * To run a mutation, you first call `useInsertParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertParticipantMutation, { data, loading, error }] = useInsertParticipantMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useInsertParticipantMutation(baseOptions?: Apollo.MutationHookOptions<InsertParticipantMutation, InsertParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertParticipantMutation, InsertParticipantMutationVariables>(InsertParticipantDocument, options);
      }
export type InsertParticipantMutationHookResult = ReturnType<typeof useInsertParticipantMutation>;
export type InsertParticipantMutationResult = Apollo.MutationResult<InsertParticipantMutation>;
export type InsertParticipantMutationOptions = Apollo.BaseMutationOptions<InsertParticipantMutation, InsertParticipantMutationVariables>;
export const DeleteParticipantDocument = gql`
    mutation deleteParticipant($id: String!) {
  deleteOneParticipant(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteParticipantMutationFn = Apollo.MutationFunction<DeleteParticipantMutation, DeleteParticipantMutationVariables>;

/**
 * __useDeleteParticipantMutation__
 *
 * To run a mutation, you first call `useDeleteParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteParticipantMutation, { data, loading, error }] = useDeleteParticipantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteParticipantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteParticipantMutation, DeleteParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteParticipantMutation, DeleteParticipantMutationVariables>(DeleteParticipantDocument, options);
      }
export type DeleteParticipantMutationHookResult = ReturnType<typeof useDeleteParticipantMutation>;
export type DeleteParticipantMutationResult = Apollo.MutationResult<DeleteParticipantMutation>;
export type DeleteParticipantMutationOptions = Apollo.BaseMutationOptions<DeleteParticipantMutation, DeleteParticipantMutationVariables>;
export const GetQuestionnairesDocument = gql`
    query getQuestionnaires($eventId: String) {
  questionnaires(where: {eventId: {equals: $eventId}}, orderBy: {order: asc}) {
    id
    name
    order
    questions {
      id
      text
      details
      order
      multiAnswer
      enabled
      options {
        id
        name
      }
      type
      mainQuestionnaire {
        id
      }
    }
    mainQuestion {
      id
      text
      details
    }
  }
}
    `;

/**
 * __useGetQuestionnairesQuery__
 *
 * To run a query within a React component, call `useGetQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnairesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetQuestionnairesQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>(GetQuestionnairesDocument, options);
      }
export function useGetQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>(GetQuestionnairesDocument, options);
        }
export type GetQuestionnairesQueryHookResult = ReturnType<typeof useGetQuestionnairesQuery>;
export type GetQuestionnairesLazyQueryHookResult = ReturnType<typeof useGetQuestionnairesLazyQuery>;
export type GetQuestionnairesQueryResult = Apollo.QueryResult<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>;
export const GetTimelineDocument = gql`
    query getTimeline($eventId: String) {
  eventSteps(where: {eventId: {equals: $eventId}}, orderBy: {startDateTime: asc}) {
    id
    name
    description
    location
    lng
    lat
    image
    location
    startDateTime
    endDateTime
  }
}
    `;

/**
 * __useGetTimelineQuery__
 *
 * To run a query within a React component, call `useGetTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimelineQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetTimelineQuery(baseOptions?: Apollo.QueryHookOptions<GetTimelineQuery, GetTimelineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimelineQuery, GetTimelineQueryVariables>(GetTimelineDocument, options);
      }
export function useGetTimelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimelineQuery, GetTimelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimelineQuery, GetTimelineQueryVariables>(GetTimelineDocument, options);
        }
export type GetTimelineQueryHookResult = ReturnType<typeof useGetTimelineQuery>;
export type GetTimelineLazyQueryHookResult = ReturnType<typeof useGetTimelineLazyQuery>;
export type GetTimelineQueryResult = Apollo.QueryResult<GetTimelineQuery, GetTimelineQueryVariables>;
export type GetAnswersQueryVariables = Exact<{
  invitationId?: InputMaybe<Scalars['String']>;
}>;


export type GetAnswersQuery = { __typename?: 'Query', answers: Array<{ __typename?: 'Answer', id: string, showQuestions: boolean, answerOption?: { __typename?: 'AnswerOption', id: string, name: string } | null, participant?: { __typename?: 'Participant', id: string, firstName: string, lastName: string, ageCategory: AgeCategory } | null, question: { __typename?: 'Question', id: string, text: string, type: AnswerType, enabled: boolean, multiAnswer: boolean, order: number, options: Array<{ __typename?: 'AnswerOption', name: string }>, mainQuestionnaire?: { __typename?: 'Questionnaire', id: string } | null, questionnaire: { __typename?: 'Questionnaire', id: string } } }> };

export type GetAnswersByQuestionIdQueryVariables = Exact<{
  invitationId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
}>;


export type GetAnswersByQuestionIdQuery = { __typename?: 'Query', answers: Array<{ __typename?: 'Answer', id: string, showQuestions: boolean, answerOption?: { __typename?: 'AnswerOption', id: string, name: string } | null, participant?: { __typename?: 'Participant', id: string, firstName: string, lastName: string, ageCategory: AgeCategory } | null, question: { __typename?: 'Question', id: string, text: string, type: AnswerType, enabled: boolean, multiAnswer: boolean, order: number, options: Array<{ __typename?: 'AnswerOption', name: string }>, mainQuestionnaire?: { __typename?: 'Questionnaire', id: string } | null, questionnaire: { __typename?: 'Questionnaire', id: string } } }> };

export type UpdateAnswerMutationVariables = Exact<{
  answerId: Scalars['String'];
  answer?: InputMaybe<Scalars['String']>;
}>;


export type UpdateAnswerMutation = { __typename?: 'Mutation', updateOneAnswer?: { __typename?: 'Answer', id: string } | null };

export type CreateParticipantAnswerMutationVariables = Exact<{
  participantId: Scalars['String'];
  questionId: Scalars['String'];
  answer: Scalars['String'];
  invitationId: Scalars['String'];
}>;


export type CreateParticipantAnswerMutation = { __typename?: 'Mutation', createOneAnswer: { __typename?: 'Answer', id: string } };

export type CreateInvitationAnswerMutationVariables = Exact<{
  questionId: Scalars['String'];
  answer: Scalars['String'];
  invitationId: Scalars['String'];
}>;


export type CreateInvitationAnswerMutation = { __typename?: 'Mutation', createOneAnswer: { __typename?: 'Answer', id: string } };

export type SetMainQuestionAnswerMutationVariables = Exact<{
  answerId: Scalars['String'];
  answer: Scalars['Boolean'];
}>;


export type SetMainQuestionAnswerMutation = { __typename?: 'Mutation', updateOneAnswer?: { __typename?: 'Answer', id: string } | null };

export type CreateMainQuestionAnswerMutationVariables = Exact<{
  questionId: Scalars['String'];
  answer: Scalars['Boolean'];
  invitationId: Scalars['String'];
}>;


export type CreateMainQuestionAnswerMutation = { __typename?: 'Mutation', updateOneQuestion?: { __typename?: 'Question', id: string } | null };

export type GetEventQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['String']>;
}>;


export type GetEventQuery = { __typename?: 'Query', event?: { __typename?: 'Event', id: string, name: string, startDate: any, endDate?: any | null, location?: string | null, capacity?: any | null } | null };

export type GetFaqQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['String']>;
}>;


export type GetFaqQuery = { __typename?: 'Query', faqItems: Array<{ __typename?: 'FaqItem', id: string, question: string, answer: string, url?: string | null, order: number }> };

export type GetInvitationQueryVariables = Exact<{
  invitationId?: InputMaybe<Scalars['String']>;
}>;


export type GetInvitationQuery = { __typename?: 'Query', invitation?: { __typename?: 'Invitation', id: string, invitee: string } | null };

export type LoginWithInvitationMutationVariables = Exact<{
  invitationId: Scalars['String'];
  eventId: Scalars['String'];
}>;


export type LoginWithInvitationMutation = { __typename?: 'Mutation', LoginWithInvitation?: { __typename?: 'InvitationAuthPayload', eventId?: string | null, invitationId?: string | null, token?: string | null } | null };

export type GetParticipantsQueryVariables = Exact<{
  invitationId?: InputMaybe<Scalars['String']>;
}>;


export type GetParticipantsQuery = { __typename?: 'Query', participants: Array<{ __typename?: 'Participant', id: string, firstName: string, lastName: string, ageCategory: AgeCategory, updatedAt: any }> };

export type UpdateParticipantMutationVariables = Exact<{
  id: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  ageCategory?: InputMaybe<AgeCategory>;
}>;


export type UpdateParticipantMutation = { __typename?: 'Mutation', updateOneParticipant?: { __typename?: 'Participant', id: string, firstName: string, lastName: string, ageCategory: AgeCategory } | null };

export type InsertParticipantMutationVariables = Exact<{
  invitationId: Scalars['String'];
}>;


export type InsertParticipantMutation = { __typename?: 'Mutation', createOneParticipant: { __typename?: 'Participant', id: string, firstName: string, lastName: string, ageCategory: AgeCategory, updatedAt: any } };

export type DeleteParticipantMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteParticipantMutation = { __typename?: 'Mutation', deleteOneParticipant?: { __typename?: 'Participant', id: string } | null };

export type GetQuestionnairesQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['String']>;
}>;


export type GetQuestionnairesQuery = { __typename?: 'Query', questionnaires: Array<{ __typename?: 'Questionnaire', id: string, name: string, order: number, questions: Array<{ __typename?: 'Question', id: string, text: string, details?: string | null, order: number, multiAnswer: boolean, enabled: boolean, type: AnswerType, options: Array<{ __typename?: 'AnswerOption', id: string, name: string }>, mainQuestionnaire?: { __typename?: 'Questionnaire', id: string } | null }>, mainQuestion?: { __typename?: 'Question', id: string, text: string, details?: string | null } | null }> };

export type GetTimelineQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['String']>;
}>;


export type GetTimelineQuery = { __typename?: 'Query', eventSteps: Array<{ __typename?: 'EventStep', id: string, name: string, description?: string | null, location?: string | null, lng?: number | null, lat?: number | null, image?: string | null, startDateTime: any, endDateTime?: any | null }> };
