import { Button, Divider, Grid, Link, Paper, Typography } from '@mui/material'

import * as React from 'react'
import { primaryColors } from 'styles/colors'

import styles from './GalleryPage.styles'

const galleryTitle = 'Galéria'
export function GalleryPage() {
  return (
    <div className={styles.container}>
      <h1>{galleryTitle}</h1>

      <Typography variant="h5" color={primaryColors.PEACH_DARK}>
        Esküvő kisfilm
      </Typography>
      <Divider></Divider>
      <iframe
        className={styles.video}
        src="https://www.youtube.com/embed/0ZA9MA9SGEY"
        title="Kisfilm"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        minHeight={'40px'}
      >
        <Paper className={styles.paper}>
          <Grid>
            <Typography variant="h5" color={primaryColors.PEACH_DARK}>
              Fotók
            </Typography>
          </Grid>
          <GalleryItem
            href="https://hajnal-vatter.familyds.com/mo/sharing/6GB48aRpI#/"
            title="Kreatív"
          />

          <GalleryItem
            href="https://hajnal-vatter.familyds.com/mo/sharing/CX60y88XW"
            title="Retusált"
          />

          <GalleryItem
            href="https://hajnal-vatter.familyds.com/mo/sharing/4WyA46SBk"
            title="Csoportképek"
          />

          <GalleryItem
            href="https://hajnal-vatter.familyds.com/mo/sharing/gDPKZyhw8"
            title="Templomi"
          />
          <GalleryItem
            href="https://hajnal-vatter.familyds.com/mo/sharing/ytoO7Pv6m"
            title="Polgári"
          />

          <GalleryItem
            href="https://hajnal-vatter.familyds.com/mo/sharing/Zj6fk5gGx"
            title="Buli"
          />

          <GalleryItem
            href="https://hajnal-vatter.familyds.com/mo/sharing/IzDyAR6ka"
            title="Kamera #2"
          />

          <GalleryItem
            href="https://hajnal-vatter.familyds.com/mo/sharing/xbXvFyen7"
            title="Vendég fotók"
          />

          <Divider className={styles.divider}></Divider>

          <GalleryItem
            href="https://hajnal-vatter.familyds.com/mo/request/79y667hFb"
            title="Feltöltés"
          />
        </Paper>
      </Grid>

      <Typography variant="h5" color={primaryColors.PEACH_DARK}>
        A mi kis történetünk
      </Typography>
      <Divider></Divider>
      <iframe
        className={styles.video}
        src="https://www.youtube.com/embed/RJizDdReKSA"
        title="Betti + Barni története"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  )
}
function GalleryItem({ href, title }: { href: string; title: string }) {
  return (
    <Grid item>
      <Button href={href} underline="hover" color="secondary" component={Link}>
        {title}
      </Button>
    </Grid>
  )
}
