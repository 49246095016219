import { primaryColors } from 'styles/colors'
import { stylesheet } from 'typestyle'

const styles = stylesheet({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: primaryColors.PEACH_LIGHT2,
  },
  bottomPhone: {
    fontSize: '18px',
  },
})

export default styles
