import * as React from 'react'
import { Grid } from '@mui/material'
import { useGetFaqQuery } from 'generated/graphql'
import { FaqItemContainer } from './FaqItem/FaqItemContainer'
import styles from './FaqPage.styles'
import Loading from 'components/general/Loading'

export function FaqPage() {
  const { data, error, loading } = useGetFaqQuery({
    fetchPolicy: 'cache-first',
  })

  if (!data || error || loading) {
    return <Loading />
  }
  return (
    <div className={styles.container}>
      <h1>Kérdések és válaszok</h1>

      {data.faqItems.map(d => {
        return (
          <Grid key={d.id} container direction="column">
            <FaqItemContainer data={d} />
          </Grid>
        )
      })}
    </div>
  )
}
