import React from 'react'
import ReactDOM from 'react-dom'
import { SnackbarProvider } from 'notistack'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ApolloProvider } from '@apollo/client'
import client from 'apolloClient'
import { BrowserRouter } from 'react-router-dom'
import Zoom from '@mui/material/Zoom'
import { primaryColors } from 'styles/colors'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <SnackbarProvider
          maxSnack={3}
          iconVariant={{ success: '🍾   ' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          TransitionComponent={Zoom}
          style={{
            backgroundColor: primaryColors.INVITATION_CARD,
            color: primaryColors.BLACK,
          }}
          autoHideDuration={2000}
        >
          <App />
        </SnackbarProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
