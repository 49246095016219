import { primaryColors } from 'styles/colors'
import { stylesheet } from 'typestyle'

const styles = stylesheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '72%',
  },
  video: {
    aspectRatio: '16 / 9',
    width: '96%',
  },
  divider: {
    borderBottomColor: primaryColors.PEACH_LIGHT1,
    borderBlockStartStyle: 'dashed',
    marginTop: '8px',
    width: '62%',
  },
  paper: {
    marginTop: '8px',
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
})

export default styles
