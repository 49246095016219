import { Grid } from '@mui/material'
import { useGetTimelineQuery } from 'generated/graphql'
import * as React from 'react'
import { TimeLineItem } from './TimelineItem/TimeLineitem'
import styles from './TimeLinePage.styles'
import { getEventId } from '../../utils/getCookies'
import Loading from 'components/general/Loading'

const timeLineTitle = 'Óráról órára'
export function TimeLinePage() {
  const { data, error, loading } = useGetTimelineQuery({
    variables: { eventId: getEventId()! },
    fetchPolicy: 'cache-first',
  })

  if (!data || error || loading) {
    return <Loading />
  }
  return (
    <div className={styles.container}>
      <h1>{timeLineTitle}</h1>

      {data.eventSteps.map(d => {
        return (
          <Grid key={d.id} container direction="column">
            <TimeLineItem data={d} />
          </Grid>
        )
      })}
    </div>
  )
}
