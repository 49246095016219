import * as React from 'react'
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import Loading from 'components/general/Loading'
import DoneIcon from '@mui/icons-material/Done'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  useGetParticipantsQuery,
  AgeCategory,
  useUpdateParticipantMutation,
  useInsertParticipantMutation,
  useDeleteParticipantMutation,
} from 'generated/graphql'
import styles from './QuestionnairePage.styles'
import { getInvitationId } from 'utils/getCookies'
import { TextCell } from './TextCell'
import { SelectCell } from './AgeItemChoices'
import { useSnackbar } from 'notistack'

export type Participant = {
  __typename?: 'Participant' | undefined
  id: string
  firstName: string
  lastName: string
  ageCategory: AgeCategory
  isEditMode?: boolean
  updatedAt: string
}

export function ParticipantTable({
  onParticipantUpdated,
}: {
  onParticipantUpdated: () => void
}) {
  const [updateParticipant] = useUpdateParticipantMutation()
  const [insertParticipant] = useInsertParticipantMutation()
  const [deleteParticipant] = useDeleteParticipantMutation()

  const [showTabel, setShowTable] = React.useState<boolean>(false)

  const defaultValues: Participant[] = [
    {
      id: '',
      firstName: '',
      lastName: '',
      ageCategory: AgeCategory.Adult,
      isEditMode: false,
      __typename: 'Participant',
      updatedAt: '',
    },
  ]
  const [rows, setRows] = React.useState<Participant[]>(defaultValues)

  const {
    data: participants,
    error,
    loading,
  } = useGetParticipantsQuery({
    variables: { invitationId: getInvitationId() },
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      setShowTable(!!data.participants.length)
      setRows(data.participants)
    },
  })

  const { enqueueSnackbar } = useSnackbar()

  const onToggleEditMode = id => {
    if (
      rows.filter(r => r.firstName === '' && r.lastName === '').length ===
      rows.length
    ) {
      enqueueSnackbar(
        <p>
          <span>A felvitt adatokat a </span>
          <span style={{ fontSize: 25, color: 'green' }}>✓</span>
          <span>-val mentheted</span>
        </p>,
        {
          autoHideDuration: 5000,
          variant: 'info',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        }
      )
    }
    setRows(() => {
      return rows.map(row => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode }
        }
        return row
      })
    })
  }

  const onSave = id => {
    setRows(() => {
      return rows.map(row => {
        if (row.id === id) {
          const { id, ageCategory, firstName, lastName } = row
          updateParticipant({
            variables: { id, ageCategory, firstName, lastName },
            onCompleted: () => {
              enqueueSnackbar('Válaszod elmentve', {
                autoHideDuration: 2000,
                variant: 'success',
              })
              onParticipantUpdated()
            },
          })
          return { ...row, isEditMode: !row.isEditMode }
        }
        return row
      })
    })
  }

  const onChange = (e, row) => {
    const value = e.target.value
    const name = e.target.name
    const { id } = row
    const newRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value }
      }
      return row
    })
    setRows(newRows)
  }
  const onDelete = id => {
    // const newRows = rows.filter(row => row.id !== id)
    deleteParticipant({
      variables: { id },
      onCompleted: () => {
        setRows([...rows.filter(row => row.id !== id)])
        onParticipantUpdated()
        enqueueSnackbar('Törölve', {
          autoHideDuration: 2000,
          variant: 'success',
        })
      },
    })

    onToggleEditMode(id)
  }

  if (!getInvitationId() || !participants || error || loading) {
    return <Loading />
  }

  return (
    <div className={styles.participantTable}>
      {showTabel && (
        <Paper>
          <Table aria-label="caption table">
            <TableHead className={styles.tableHeader}>
              <TableRow>
                <TableCell align="left" />
                <TableCell align="left">Vezetéknév</TableCell>
                <TableCell align="left">Utónév</TableCell>
                <TableCell align="left">Korosztály</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id}>
                  <TableCell key={row.id}>
                    {row.isEditMode ? (
                      <>
                        <IconButton
                          aria-label="done"
                          onClick={() => onSave(row.id)}
                        >
                          <DoneIcon style={{ fill: 'green' }} />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => onDelete(row.id)}
                        >
                          <DeleteIcon style={{ fill: 'red' }} />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton
                        aria-label="edit"
                        onClick={() => onToggleEditMode(row.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TextCell {...{ row, name: 'lastName', onChange }} />
                  <TextCell {...{ row, name: 'firstName', onChange }} />
                  <SelectCell {...{ row, name: 'ageCategory', onChange }} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
      <Button
        className={styles.addParticipantButton}
        variant="contained"
        onClick={async () => {
          setShowTable(true)
          insertParticipant({
            variables: { invitationId: getInvitationId()! },
            onCompleted: data => {
              setRows([...rows, data.createOneParticipant])
              onParticipantUpdated()
            },
          })
        }}
      >
        Résztvevő hozzáadása
      </Button>
    </div>
  )
}
