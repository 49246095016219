import { primaryColors } from 'styles/colors'
import { stylesheet } from 'typestyle'

const styles = stylesheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableHeader: {
    backgroundColor: primaryColors.INVITATION_CARD,
  },
  questionnairePage: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  participantTable: {
    marginTop: '20px',
    display: 'flex',
    marginLeft: '10px',
    marginRight: '10px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  switch: {
    $nest: {
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: primaryColors.PEACH_DARK,
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: primaryColors.PEACH_LIGHT1,
      },
    },
  },
  questionTable: { marginBottom: '15px' },
  info: {
    marginLeft: '4px',
    marginRight: '4px',
    textAlign: 'center',
  },
  addParticipantButton: {
    marginTop: '20px',
    backgroundColor: primaryColors.PEACH_DARK,
    color: primaryColors.WHITE,
    $nest: {
      '&:hover': {
        backgroundColor: primaryColors.PEACH_LIGHT1,
      },
    },
  },
})

export default styles
