import { stylesheet } from 'typestyle'

const styles = stylesheet({
  container: {
    // fontFamily:
    //   'Baskerville, "Baskerville Old Face", "Hoefler Text", Garamond, "Times New Roman", serif',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage:
      'https://static0.planning.wedding/website/MPhVdsKrv/cover/da5563a20f29_normal.jpg',
    backgroundSize: 'cover',
    backgroundPositionX: '100%',
    height: '100%',
  },
  footer: {
    position: 'fixed',
    height: '50px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    marginBottom: '0px',
  },
  body: { marginBottom: '50px', display: 'flex', flexDirection: 'column' },
})

export default styles
