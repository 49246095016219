/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint react/prop-types: 0 */
import * as React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { FaqItem } from 'generated/graphql'
import { CardActions, Collapse, Icon, IconButton, styled } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useHistory } from 'react-router'
import styles from './FaqItemContainer.styles'

type FaqItemData = Omit<
  FaqItem,
  'createdAt' | 'updatedAt' | 'event' | 'eventId'
>

type FaqItemProps = {
  data: FaqItemData
}

const ExpandMore = styled((props: any) => {
  const { expand, ...other }: any = props

  return <IconButton {...other} />
})(({ theme, expand }: any) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))
export function FaqItemContainer({ data }: FaqItemProps) {
  const [expanded, setExpanded] = React.useState(false)
  const history = useHistory()

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card className={styles.container}>
      <CardHeader
        className={styles.title}
        titleTypographyProps={{ fontWeight: '800', fontSize: '18px' }}
        title={data.question}
      />

      <CardActions disableSpacing>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className={styles.answer}>
            <Typography paragraph>{data.answer}</Typography>
          </CardContent>
          {data.url && (
            <a href={data.url} target="_blank" rel="noreferrer">
              <Typography paragraph>{data.url}</Typography>
            </a>
          )}
        </Collapse>
        <ExpandMore
          key={data.id}
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
    </Card>
  )
}
