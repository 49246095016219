import { stylesheet } from 'typestyle'
import { primaryColors } from '../../../styles/colors'

const styles = stylesheet({
  container: {
    marginBottom: '20px',
    borderRadius: '15px',
    maxWidth: '550px',
  },
  title: {
    backgroundColor: primaryColors.PEACH_LIGHT2,
  },
  answer: {
    textAlign: 'justify',
  },
})

export default styles
