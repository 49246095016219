/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint react/prop-types: 0 */
import * as React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import DirectionsIcon from '@mui/icons-material/Directions'
import { EventStep } from 'generated/graphql'
import {
  Button,
  CardActions,
  Collapse,
  IconButton,
  styled,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useHistory } from 'react-router'
import { useTheme, Theme } from '@mui/material'
import styles from './TimeLineitem.styles'

type TimeLineItem = Omit<
  EventStep,
  'createdAt' | 'updatedAt' | 'event' | 'eventId'
>

type TimeLineItemProps = {
  data: TimeLineItem
}

const ExpandMore = styled((props: any) => {
  const { expand, ...other }: any = props
  const theme: Theme = useTheme()

  return <IconButton {...other} />
})(({ theme, expand }: any) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))
export function TimeLineItem({ data }: TimeLineItemProps) {
  const [expanded, setExpanded] = React.useState(false)
  const history = useHistory()

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card className={styles.container}>
      <CardHeader
        className={styles.title}
        avatar={
          <img
            className={styles.avatar}
            height="40px"
            src={data.image || 'bb-heart2.svg'}
          ></img>
        }
        {...(data.lng &&
          data.lat && {
            action: (
              <LocationOnIcon
                className={styles.locationIcon}
                style={{ marginTop: '20px' }}
              />
            ),
          })}
        title={new Date(data.startDateTime).toLocaleTimeString('hu-HU', {
          timeStyle: 'short',
          hour12: false,
        })}
        titleTypographyProps={{ fontWeight: '800', fontSize: '16px' }}
        subheader={data.name}
        subheaderTypographyProps={{ fontSize: '18px' }}
      />

      {(data.description || data.location) && (
        <CardActions disableSpacing>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography className={styles.answer} paragraph>
                {data.description}
              </Typography>
            </CardContent>
            {data.location && (
              <CardContent className={styles.locationContainer}>
                <Button
                  className={styles.locationButtonContainer}
                  key={'menuitem'}
                  target="_blank"
                  href={`https://www.google.com/maps/dir/?api=1&destination=${data.lat},${data.lng}&travelmode=driving`}
                >
                  <DirectionsIcon className={styles.directionsIcon} />
                </Button>
                <Typography className={styles.answer} paragraph>
                  {data.location}
                </Typography>
              </CardContent>
            )}
          </Collapse>
          <ExpandMore
            key={data.id}
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
      )}
    </Card>
  )
}
