import * as React from 'react'
import { Grid } from '@mui/material'
import {
  useGetAnswersQuery,
  GetQuestionnairesQuery,
  GetParticipantsQuery,
  useCreateMainQuestionAnswerMutation,
  useSetMainQuestionAnswerMutation,
} from 'generated/graphql'
import { getInvitationId } from 'utils/getCookies'
import { Mainquestion } from './Mainquestion'
import { MultiAnswerQuestionTable } from './MultiAnswerQuestionTable'
import { SingleAnswerQuestionTable } from './SingleAnswerQuestionTable'
import styles from './QuestionnairePage.styles'
import Loading from '../general/Loading'

export function QuestionnaireTable({
  questionnaires,
  participants,
  onChangeMainAnswer = () => {},
}: {
  questionnaires: GetQuestionnairesQuery['questionnaires']
  participants: GetParticipantsQuery['participants']
  onChangeMainAnswer: () => void
}) {
  const [setMainQuestionAnswer] = useSetMainQuestionAnswerMutation()
  const [createMainQuestionAnswer] = useCreateMainQuestionAnswerMutation()

  const [showQuestions, setShowQuestions] = React.useState<
    {
      id: string
      questionId: string
      showQuestions: boolean
    }[]
  >([])

  const { data: answer } = useGetAnswersQuery({
    variables: { invitationId: getInvitationId() },
    onCompleted: data => {
      if (data.answers.length) {
        setShowQuestions(
          data.answers.map(a => {
            return {
              id: a.id,
              questionId: a.question.id,
              showQuestions: a.showQuestions,
            }
          })
        )
      }
    },
  })

  if (!answer || !showQuestions) {
    return <Loading />
  }
  return (
    <div key={'questionnaire_table'} className={styles.questionnairePage}>
      {questionnaires.map(d => {
        const mainAnswer = showQuestions.find(
          a => a.questionId === d.mainQuestion!.id
        )
        return (
          <Grid key={d.id} container direction="column">
            {d.mainQuestion && (
              <Mainquestion
                question={{
                  details: d.mainQuestion.details || undefined,
                  id: d.mainQuestion.id,
                  text: d.mainQuestion.text,
                }}
                checked={!!mainAnswer?.showQuestions}
                onChange={value => {
                  mainAnswer?.showQuestions || mainAnswer?.id
                    ? setMainQuestionAnswer({
                        variables: {
                          answerId: mainAnswer.id,
                          answer: value,
                        },
                        onCompleted: () => {
                          onChangeMainAnswer()
                        },
                      })
                    : createMainQuestionAnswer({
                        variables: {
                          questionId: d.mainQuestion!.id,
                          answer: value,
                          invitationId: getInvitationId()!,
                        },
                        onCompleted: () => {
                          onChangeMainAnswer()
                        },
                      })
                  setShowQuestions(
                    showQuestions.map(s => {
                      if (s.questionId === d.mainQuestion!.id) {
                        return { ...s, showQuestions: value }
                      } else {
                        return { ...s }
                      }
                    })
                  )
                }}
              />
            )}

            {mainAnswer?.showQuestions &&
              d.questions
                .filter(q => !q.mainQuestionnaire?.id)
                .sort((a, b) => a.order - b.order)
                .map(q => {
                  return (
                    <>
                      {q.multiAnswer ? (
                        <MultiAnswerQuestionTable
                          question={{ ...q, details: q.details || '' }}
                          participants={participants}
                          key={`${q.id}-${
                            participants.sort((a, b) => {
                              return a.updatedAt - b.updatedAt
                            })[0].updatedAt
                          }`}
                        />
                      ) : (
                        <SingleAnswerQuestionTable
                          question={{ ...q, details: q.details || '' }}
                          key={`${q.id}-${
                            participants.sort((a, b) => {
                              return a.updatedAt - b.updatedAt
                            })[0].updatedAt
                          }`}
                        />
                      )}
                    </>
                  )
                })}
          </Grid>
        )
      })}
    </div>
  )
}
