import { primaryColors } from 'styles/colors'
import { stylesheet } from 'typestyle'

const styles = stylesheet({
  imageIcon: { height: '100%', width: '100%' },
  toolbar: {
    backgroundColor: primaryColors.PEACH_LIGHT2,
  },
})

export default styles
