import { stylesheet } from 'typestyle'
import { primaryColors } from '../../../styles/colors'

const styles = stylesheet({
  paper: {
    padding: '8px',
    border: '2px solid',
    borderRadius: '4px',
    borderColor: primaryColors.PEACH_LIGHT2,
  },
  name: { fontSize: '22px' },
  contact: {},
  bottomNavigation: { backgroundColor: primaryColors.PEACH_LIGHT2 },
})

export default styles
