import { Button } from '@mui/material'
// import CountDown from 'components/CountDown/CountDown'
import React from 'react'
// import dayjs from 'dayjs'

import styles from './HomePage.styles'
import { ROUTES } from 'components/Routes'
import { useHistory } from 'react-router'
import { useGetInvitationQuery, useGetEventQuery } from 'generated/graphql'
import { getEventId, getInvitationId } from 'utils/getCookies'
import Loading from 'components/general/Loading'

// const date = new Date(2022, 8, 23, 13, 0, 0)
// const formattedDate = dayjs(date).format('YYYY.MM.DD')
export function HomePage() {
  const history = useHistory()

  const {
    data: eventData,
    error: eventError,
    loading: eventLoading,
  } = useGetEventQuery({
    variables: { eventId: getEventId() },
    fetchPolicy: 'cache-first',
  })

  const { data, error, loading } = useGetInvitationQuery({
    variables: { invitationId: getInvitationId() },
    fetchPolicy: 'cache-first',
  })

  if (
    loading ||
    eventLoading ||
    error ||
    eventError ||
    !data?.invitation?.invitee ||
    !eventData?.event?.name
  ) {
    return <Loading />
  }

  return (
    <div className={styles.container}>
      <img src="home_page_betti_barni.jpeg" className={styles.coverImage} />

      <h3>Kedves {data.invitation.invitee}!</h3>
      <div className={styles.welcome}>
        Üdvözlünk esküvői naplónkban, amelyet azért hoztunk létre, hogy
        megosszuk veletek a nagy nap részleteit.
      </div>
      {/* 
      TODO: Compare dates and add countdow for future dates only
      <div className={styles.welcome}>
        Egy következő fejezet veszi kezdetét életünkben, ugyanis
      </div>
      <div>
        <CountDown date={date}></CountDown>
      </div>

      <h5>{`...múlva ${formattedDate}-án kimondjuk az IGEN-t.`}</h5> */}

      <div className={styles.welcome}>
        {'Feltöltöttük a '}
        <a
          className={styles.url}
          onClick={() => {
            history.push(ROUTES.TIMELINE)
          }}
        >
          menetrendet
        </a>
        {', pár hasznos '}
        <a
          className={styles.url}
          onClick={() => {
            history.push(ROUTES.FAQ)
          }}
        >
          információt
        </a>
        {', a '}
        <a
          className={styles.url}
          onClick={() => {
            history.push(ROUTES.GALLERY)
          }}
        >
          galéria
        </a>
        {
          ' menüpont alatt találjátok majd az esküvőn készült képeket és videókat, illetve itt várunk '
        }
        <a
          className={styles.url}
          onClick={() => {
            history.push(ROUTES.QUESTIONNAIRE)
          }}
        >
          visszajelzést
        </a>
        {' tőletek, hogy részt tudtok-e venni az eseményen.'}
      </div>

      {/* <div className={styles.avatarContainer}>
        <Avatar
          className={styles.avatarBetti}
          alt="BB"
          src="betti-avatar.png"
        />
        <Avatar
          className={styles.avatarBarni}
          alt="BB"
          src="barni-avatar.png"
        />
      </div> */}

      <h3>Reméljük eljöttök!</h3>

      <Button
        className={styles.button}
        variant="outlined"
        onClick={() => {
          history.push(ROUTES.QUESTIONNAIRE)
        }}
      >
        Ott leszünk
      </Button>

      <div className={styles.welcome}>
        Az alábbi elérhetőségeink egyikén keressetek bátran, ha kérdésetek
        merülne fel.
      </div>
    </div>
  )
}
