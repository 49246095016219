import { primaryColors } from 'styles/colors'
import { keyframes, stylesheet } from 'typestyle'

const growAnimationBetti = keyframes({
  '0%': { transform: 'scale(0.7) translate(-70%, -70%);' },
  '50%': { transform: 'rotate(1.1turn)' },
  '100%': { transform: 'scale(0.7) translate(-70%, -70%)' },
})
const growAnimationBarni = keyframes({
  '0%': { transform: 'rotate(1.1turn)' },
  '50%': { transform: 'scale(0.7) translate(-70%, -70%);' },
  '100%': { transform: 'rotate(1.1turn)' },
})

const buttonAnimation = keyframes({
  '20%': { transform: 'scale(1.2)' },
  '50%': { transform: 'rotateX(360deg)' },
  '60%': { transform: 'scale(1)' },
  '80%': { transform: 'scale(1.2)' },
  '100%': {},
})

const styles = stylesheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    maxWidth: '920px',
  },
  coverImage: {
    width: '100%',
    maxWidth: '920px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  welcome: {
    textAlign: 'justify',
    margin: '4%',
  },
  url: {
    color: primaryColors.PEACH_DARK,
    cursor: 'pointer',
    $nest: {
      '&:hover': { color: primaryColors.RED, textDecoration: 'underline' },
    },
  },
  button: {
    borderColor: primaryColors.PEACH_DARK,
    color: primaryColors.PEACH_LIGHT1,
    marginBottom: '40px',
    $nest: {
      '&:hover': {
        borderColor: primaryColors.RED,
        color: primaryColors.WHITE,
        backgroundColor: primaryColors.PEACH_LIGHT1,
        animationName: buttonAnimation,
        animationDuration: '1s',
        animationIterationCount: 'infinite',
      },
    },
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatarBetti: {
    width: '6rem',
    height: '6rem',
    animationName: growAnimationBetti,
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    margin: '20px',
  },
  avatarBarni: {
    width: '6rem',
    height: '6rem',
    animationName: growAnimationBarni,
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    margin: '20px',
  },
})

export default styles
