import * as React from 'react'
import { MenuItem, Select, TableCell } from '@mui/material'
import { AgeCategory } from 'generated/graphql'

export function getAgeChoices() {
  const enumTypes = Object.keys(AgeCategory)

  return enumTypes.map(p => ({
    id: p,
    name: mappedAgeCategories(p),
  }))
}

function mappedAgeCategories(categoryName: string) {
  return categoryName === AgeCategory.Adult
    ? '11+'
    : categoryName === AgeCategory.Child
    ? '3-10 év'
    : '0-2 év'
}

export type MenuItemChoiceProps = {
  value: string
  onChange: (e, row: any) => void
  row: any
}

export const SelectCell = ({
  row,
  name,
  onChange,
}: {
  row: any
  name: string
  onChange: (e, val) => void
}) => {
  const { isEditMode } = row
  return (
    <TableCell align="left">
      {isEditMode ? (
        <>
          <AgeItemChoices value={row[name]} onChange={onChange} row={row} />
        </>
      ) : (
        mappedAgeCategories(row[name])
      )}
    </TableCell>
  )
}

export function AgeItemChoices({ value, onChange, row }: MenuItemChoiceProps) {
  return (
    <Select
      defaultValue={AgeCategory[value] || AgeCategory.Adult}
      name="ageCategory"
      onChange={e => {
        onChange(e, row)
      }}
    >
      {getAgeChoices().map(c => {
        return (
          <MenuItem key={c.id} value={c.id}>
            {c.name}
          </MenuItem>
        )
      })}
    </Select>
  )
}
