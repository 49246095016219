import React, { useState } from 'react'

import styles from './App.styles'

import ResponsiveAppBar from 'components/AppBar/AppBar'

import { Route, Switch } from 'react-router-dom'
import { HomePage } from 'components/HomePage/HomePage'
import { FaqPage } from 'components/FaqPage/FaqPage'
import { QuestionnairePage } from 'components/QuestionnairePage/QuestionnairePage'
import { ROUTES } from 'components/Routes'
import { TimeLinePage } from 'components/TimeLinePage/TimeLinepage'
import BottomBar from 'components/BottomBar/BottomBar'
import { GalleryPage } from 'components/GalleryPage/GalleryPage'
import {
  invitationIdCookieKey,
  eventIdCookieKey,
  getInvitationId,
  getEventId,
} from './utils/getCookies'
import { useHistory } from 'react-router'
import { ThemeProvider, createTheme } from '@mui/material'
import { primaryColors } from 'styles/colors'
import Loading from 'components/general/Loading'
import { useLoginWithInvitationMutation } from 'generated/graphql'
import jwt_decode, { JwtPayload } from 'jwt-decode'

function getSearhParam(name: string) {
  const params = new URLSearchParams(window.location.search)

  return params.get(name)
}

export function getInvitationIdSearchParam() {
  return getSearhParam(invitationIdCookieKey)
}
export function getEventIdSearchParam() {
  return getSearhParam(eventIdCookieKey)
}
export const useHasInvitationAndEventId = () => {
  const history = useHistory()

  const [allowUser, setAllowUser] = useState<boolean>(false)

  const invitationId = getInvitationIdSearchParam()
  const eventId = getEventIdSearchParam()
  const token = localStorage.getItem('token')

  const [login] = useLoginWithInvitationMutation({
    onCompleted: data => {
      if (data.LoginWithInvitation?.token) {
        localStorage.setItem('token', data.LoginWithInvitation.token)
        setAllowUser(true)
      }
    },
  })
  if (!allowUser) {
    if (token) {
      const decodedToken = jwt_decode(token) as JwtPayload
      setAllowUser(
        [invitationId, getInvitationId()].includes(decodedToken.jti) &&
          [eventId, getEventId()].includes(decodedToken.sub)
      )
    } else if (invitationId && eventId) {
      localStorage.setItem(invitationIdCookieKey, invitationId)
      localStorage.setItem(eventIdCookieKey, eventId)

      login({
        variables: {
          invitationId,
          eventId,
        },
        onCompleted: data => {
          if (data.LoginWithInvitation?.token) {
            localStorage.setItem('token', data.LoginWithInvitation.token)
            setAllowUser(true)
          }
        },
      })

      history.push('/')
    }
  }

  return allowUser
}

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColors.PEACH_LIGHT2,
    },
    secondary: {
      main: primaryColors.PEACH_DARK,
    },
  },
})

function App() {
  const allowUser = useHasInvitationAndEventId()

  if (!allowUser) {
    return <Loading />
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.container}>
        <header>
          <ResponsiveAppBar />
        </header>
        <div className={styles.body}>
          <Switch>
            <Route exact path={ROUTES.QUESTIONNAIRE}>
              <QuestionnairePage />
            </Route>

            <Route exact path={ROUTES.FAQ}>
              <FaqPage />
            </Route>

            <Route exact path={ROUTES.HOMEPAGE}>
              <HomePage />
            </Route>
            <Route exact path={ROUTES.TIMELINE}>
              <TimeLinePage />
            </Route>
            <Route exact path={ROUTES.GALLERY}>
              <GalleryPage />
            </Route>
          </Switch>
        </div>
        <div className={styles.footer}>
          <BottomBar />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default App
