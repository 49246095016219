import * as React from 'react'

import { CircularProgress } from '@mui/material'
import { primaryColors } from 'styles/colors'

export default function Loading() {
  return (
    <CircularProgress
      sx={{
        marginTop: '18px',
        color: primaryColors.RED,
        alignSelf: 'center',
      }}
    />
  )
}
